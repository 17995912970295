// orderJsonKeys.js
export const orderJsonKeys = (config, deviceType) => {
    if (deviceType === "modbustcpip") {
      // Format for modbustcpip devices
      return {
        device_config: config.device_config.map(device => ({
          devicename: device.devicename,
          protocol: device.protocol,
          address: device.address,
        })),
        configlist: Object.fromEntries(
          Object.entries(config.configlist).map(([key, value]) => [
            key,
            {
              version: value.version,
              publisher: {
                destination: value.publisher.destination,
                protocol: value.publisher.protocol,
                sample_rate: value.publisher.sample_rate,
                debug: value.publisher.debug,
              },
              devicename: value.devicename,
              enable: value.enable,
              tcpip_port: {
                ip: value.tcpip_port.ip,
                port: value.tcpip_port.port,
                port_timeout: value.tcpip_port.port_timeout,
              },
              query: value.query.map(query => ({
                slave_id: query.slave_id,
                start_address: query.start_address,
                input_type: query.input_type,
                num_of_registers: query.num_of_registers,
                peripheral_name: query.peripheral_name,
                interval: query.interval,
                peripheral_id: query.peripheral_id,
                data_share: query.data_share,
                querycnt: query.querycnt,
                parameter: query.parameter.map(param => ({
                  data_type: param.data_type,
                  adjustment_factor: param.adjustment_factor,
                  converter: param.converter,
                  property_name: param.property_name,
                  param: param.param,
                  isAddParam: param.isAddParam,
                  totalParam: param.totalParam,
                  uuid: param.uuid,
                })),
                isAddQuery: query.isAddQuery,
                totQuery: query.totQuery,
                queryinterval: query.queryinterval,
              })),
            },
          ])
        ),
      };
    } else if (deviceType === "modbusrtu") {
      // Format for modbusrtu devices (as provided earlier)
      return {
        device_config: config.device_config.map(device => ({
          devicename: device.devicename,
          protocol: device.protocol,
          address: device.address,
        })),
        configlist: Object.fromEntries(
          Object.entries(config.configlist).map(([key, value]) => [
            key,
            {
              publisher: {
                destination: value.publisher.destination,
                protocol: value.publisher.protocol,
                debug: value.publisher.debug,
                sample_rate: value.publisher.sample_rate,
                devicename: value.publisher.devicename,
              },
              tcpip_port: value.tcpip_port,
              serial_port: {
                device: value.serial_port.device,
                baud_rate: value.serial_port.baud_rate,
                parity: value.serial_port.parity,
                data_bits: value.serial_port.data_bits,
                stop_bits: value.serial_port.stop_bits,
                port_timeout: value.serial_port.port_timeout,
              },
              enable: value.enable,
              query: value.query.map(query => ({
                slave_id: query.slave_id,
                start_address: query.start_address,
                input_type: query.input_type,
                num_of_registers: query.num_of_registers,
                peripheral_name: query.peripheral_name,
                interval: query.interval,
                peripheral_id: query.peripheral_id,
                data_share: query.data_share,
                querycnt: query.querycnt,
                parameter: query.parameter.map(param => ({
                  data_type: param.data_type,
                  adjustment_factor: param.adjustment_factor,
                  converter: param.converter,
                  property_name: param.property_name,
                  param: param.param,
                  isAddParam: param.isAddParam,
                  totalParam: param.totalParam,
                  uuid: param.uuid,
                })),
                isAddQuery: query.isAddQuery,
                totQuery: query.totQuery,
                queryinterval: query.queryinterval,
              })),
              devicename: value.devicename,
            },
          ])
        ),
      };
    } else if (deviceType === "ethernet") {
    // Format for Ethernet devices
    return {
      request: "set_config",
      request_parameters: {
        publisher: {
          destination: ["service_transport"],
          sampling_rate: 60,
          debug: 0,
        },
        profile: "eth0",
        ethernet: {
          enable: 1,
          mode: 0,
          ipaddr: config.ethernet.request_parameters.ethernet.ipaddr,
          netmask: config.ethernet.request_parameters.ethernet.netmask,
          gateway: config.ethernet.request_parameters.ethernet.gateway,
          preferDns: config.ethernet.request_parameters.ethernet.preferDns,
          AlterDns: config.ethernet.request_parameters.ethernet.AlterDns,
        },
      },
    };
  } else if (deviceType === "modem") {
    // Modem payload structure
    return {
      request: "set_config",
      request_parameters: {
        publisher: {
          destination: ["service_transport"],
          sampling_rate: 60,
          debug: 0,
        },
        profile: "lte", // Modem profile
        modem: {
          enable: config.GDM.request_parameters.modem.enable,
          apn: config.GDM.request_parameters.modem.apn,
          modemType: config.GDM.request_parameters.modem.modemType,
          serialport: config.GDM.request_parameters.modem.serialport,
        },
      },
    };
  } 
  
    // Default return for other device types if needed
    return config;
  };
  