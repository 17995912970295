import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Stack,
  IconButton,
  Snackbar,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Tooltip,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import mqtt from "mqtt";
import SaveIcon from "@mui/icons-material/Save";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { tableCellTheme,  } from "../../theme/tableCellTheme";
import { tableCellTheme, tableBodyCellTheme } from "../../theme/tableCellTheme";
import { orderJsonKeys } from "../Utils/ModbusUtils";
// import tableBodyClasses from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
  },
});

const mqtt_topics = {
  analog: "service_analog_in/service_webui_PH:QT",
  digital: "service_di_in/service_webui_PH:QT",
  modbusrtu: "service_device/service_importdev:QT",
  // modbusrtu:"service_importdev/service_device:RT",
  modbustcpip: "service_device/service_importdev:QT",
  // modbustcpip:"service_importdev/service_device:RT",
  "cim-cloud": "service_transport/service_importdev:QT",
  mqtt: "service_transport/service_importdev:QT",
  ethernet: "service_webui_network/service_eth0:QT",
  modem: "service_webui_network/service_modem:QT",
};

const DeviceList = ({}) => {
  const [devices, setDevices] = useState([]);
  // const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modbusrtuConfig, setModbusrtuConfig] = useState(null);
  const [modbustcpipConfig, setModbustcpipConfig] = useState(null);
  const [CIMCloudConfig, setCIMCloudConfig] = useState(null);
  const [MQTTCloudConfig, setMQTTCloudConfig] = useState(null);
  const userId = localStorage.getItem("userId");
  const conversationId = sessionStorage.getItem("conversationId");

  // Helper function to determine device status and return appropriate color for LED
  const getStatusColor = (status) => {
    switch (status) {
      case "online":
        return "green";
      case "offline":
        return "red";
      default:
        return "grey"; // For unknown status
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "online":
        return "Device is online";
      case "offline":
        return "Device is offline";
      default:
        return "Status unknown";
    }
  };

  // const statusMessage = getStatusMessage(selectedDevice.status);

  // Fetch devices
  const fetchDevices = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/user/cimdevices/get/${userId}/`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch devices");
      }
      const data = await response.json();
      setDevices(data);
  
      // Check if a device is already selected in sessionStorage
      const storedDevice = sessionStorage.getItem("selectedDevice");
      if (storedDevice) {
        // If there's a selected device in storage, use that
        setSelectedDevice(JSON.parse(storedDevice));
      } else if (data.length > 0) {
        // If no selected device in storage and devices are available, set the first device
        const firstDevice = data[0];
        setSelectedDevice(firstDevice);
        sessionStorage.setItem("selectedDevice", JSON.stringify(firstDevice));
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };
  

  // useEffect(() => {
  //   fetchDevices(userId);
  // }, [userId]);

  useEffect(() => {
    // Fetch devices immediately when the component mounts
    fetchDevices(userId);

    // Set up polling using setInterval
    const intervalId = setInterval(() => {
      fetchDevices(userId);
    }, 3000); // Poll every 5 seconds

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [userId]);

  // Handle radio selection change
  const handleRadioChange = (event) => {
    const selectedDeviceId = event.target.value;
    const selectedDeviceObj = devices.find(
      (device) => device.device_id === selectedDeviceId,
    );
    setSelectedDevice(selectedDeviceObj);
    sessionStorage.setItem("selectedDevice", JSON.stringify(selectedDeviceObj));
    setErrorMessage("");
  };

  useEffect(() => {
    // Retrieve selected device from sessionStorage on component mount
    const storedDevice = sessionStorage.getItem("selectedDevice");
    if (storedDevice) {
      setSelectedDevice(JSON.parse(storedDevice)); // Set the state with the stored device
    }
  }, []);

  // const handleRadioChange = (event) => {
  //   const selectedDeviceId = event.target.value;
  //   const selectedDeviceObj = devices.find((device) => device.device_id === selectedDeviceId);
  //   setSelectedDevice(selectedDeviceObj);

  //   // Save selected device to localStorage
  //   localStorage.setItem("selectedDevice", JSON.stringify(selectedDeviceObj));
  //   setErrorMessage("");
  // };

  // Retrieve selected device from localStorage on component mount
  // useEffect(() => {
  //   const storedDevice = localStorage.getItem("selectedDevice");
  //   if (storedDevice) {
  //     setSelectedDevice(JSON.parse(storedDevice));
  //   }
  // }, []);

  const handlePushConfig = () => {
    if (!selectedDevice) {
      setErrorMessage("Please select a CIM10 device to push the configuration");
      return;
    }
    handlePushConfigClick(conversationId);
  };

  const [pushConfigSnackbar, setPushConfigSnackbar] = useState({
    open: false,
    message: "",
  });

  // const uploadConfig = async (conversationId, setPushConfigSnackbar) => {
  //   console.log("Starting uploadConfig function");

  //   try {
  //     console.log(
  //       `Fetching configuration for conversationId: ${conversationId}`,
  //     );

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
  //       {
  //         headers: {
  //           // Add necessary headers here if needed
  //         },
  //       },
  //     );

  //     console.log("Response received from API:", response.data);

  //     const configurationPayload = response.data.configuration_payload;
  //     console.log("Configuration Payload:", configurationPayload);

  //     const deviceType = response.data.device_type.toLowerCase();
  //     console.log("Device Type:", deviceType);

  //     const mqtt_topic = mqtt_topics[deviceType];
  //     console.log("Determined MQTT Topic:", mqtt_topic);

  //     if (!mqtt_topic) {
  //       console.error(`Unknown device type: ${deviceType}`);
  //       setPushConfigSnackbar({
  //         open: true,
  //         message: `Unknown device type: ${deviceType}`,
  //       });
  //       return;
  //     }

  //     // Determine the correct payload structure
  //     let payload;
  //     if (deviceType === "modbusrtu" || deviceType === "modbustcpip") {
  //       console.log("Device type requires 'device_import' request type");

  //       const selectedDevice = {
  //         devicename: response.data.device_name, // Assuming device_name is available in the response
  //         protocol: deviceType,
  //         address: "", // Assuming address can be left empty or populated as needed
  //       };

  //       configurationPayload.export_device.selecteddevice = [selectedDevice];

  //       // Extract the payload from the `export_device` parameter
  //       const exportDevicePayload = configurationPayload.export_device;
  //       payload = JSON.stringify({
  //         request: "device_import",
  //         request_parameters: exportDevicePayload,
  //       });

  //       // payload =JSON.stringify(configurationPayload);
  //       console.log("the payload is: ", configurationPayload);
  //       console.log("The json stringified payload is: ", payload);
  //     }
  //     if (deviceType === "CIM-Cloud" || deviceType === "MQTT") {
  //       const selectedtransport = {
  //         devicename: response.data.device_name, // Assuming device_name is available in the response
  //         protocol: deviceType,
  //         // address: "" // Assuming address can be left empty or populated as needed
  //       };

  //       configurationPayload.export_device.selectedtransport = [
  //         selectedtransport,
  //       ];

  //       // Extract the payload from the `export_device` parameter
  //       const exportDevicePayload = configurationPayload.export_device;
  //       payload = JSON.stringify({
  //         request: "transport_import",
  //         request_parameters: exportDevicePayload,
  //       });
  //     } else {
  //       console.log("Standard device type, using 'set_config' request type");

  //       payload = JSON.stringify({
  //         request: "set_config",
  //         request_parameters: configurationPayload,
  //       });
  //     }
  //     console.log("Final Payload:", payload);

  //     // Connect to the MQTT broker using the correct TCP protocol
  //     console.log("Connecting to MQTT broker");
  //     const mqttBrokerUrl = `mqtt://${selectedDevice.ip_address}:9001`;
  //     console.log("mqtt IP set dynamic: ", mqttBrokerUrl);
  //     const client = mqtt.connect(mqttBrokerUrl);

  //     client.on("connect", () => {
  //       console.log("MQTT client connected");

  //       client.publish(mqtt_topic, payload, (error) => {
  //         if (error) {
  //           console.error("Error publishing to MQTT:", error.message);
  //           setPushConfigSnackbar({
  //             open: true,
  //             message: "Failed to publish configuration.",
  //           });
  //         } else {
  //           console.log("Configuration published successfully!");
  //           setPushConfigSnackbar({
  //             open: true,
  //             message:
  //               "Your configuration has been updated. Please check iEdge360 portal of your CIM10.",
  //           });

  //         }
  //         client.end(); // Disconnect from the broker
  //         console.log("MQTT client disconnected");
  //       });
  //     });

  //     client.on("error", (err) => {
  //       console.error("MQTT client error:", err);
  //       setPushConfigSnackbar({ open: true, message: "MQTT client error." });
  //     });
  //   } catch (error) {
  //     console.error("Error in uploadConfig function:", error.message);
  //     if (error.response) {
  //       console.error("Response data:", error.response.data);
  //     }
  //     setPushConfigSnackbar({
  //       open: true,
  //       message: "Error fetching or uploading configuration.",
  //     });
  //   }
  // };

  // const uploadConfig = async (conversationId, setPushConfigSnackbar) => {
  //   console.log("Starting uploadConfig function");

  //   try {
  //     console.log(`Fetching configuration for conversationId: ${conversationId}`);

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
  //       {
  //         headers: {
  //           // Add necessary headers here if needed
  //         },
  //       }
  //     );

  //     console.log("Response received from API:", response.data);

  //     const configurationPayload = response.data.configuration_payload;
  //     console.log("Configuration Payload:", configurationPayload);

  //     const deviceType = response.data.device_type.toLowerCase();
  //     console.log("Device Type:", deviceType);

  //     const mqtt_topic = mqtt_topics[deviceType];
  //     console.log("Determined MQTT Topic:", mqtt_topic);

  //     if (!mqtt_topic) {
  //       console.error(`Unknown device type: ${deviceType}`);
  //       setPushConfigSnackbar({
  //         open: true,
  //         message: `Unknown device type: ${deviceType}`,
  //       });
  //       return;
  //     }

  //     let payload;
  //     let requestType;

  //     if (deviceType === "modbusrtu" || deviceType === "modbustcpip") {
  //       console.log("Device type requires 'device_import' request type");

  //       const selectedDevice = {
  //         devicename: response.data.device_name,
  //         protocol: deviceType,
  //         address: "",
  //       };

  //       configurationPayload.export_device.selecteddevice = [selectedDevice];

  //       const exportDevicePayload = configurationPayload.export_device;
  //       payload = JSON.stringify({
  //         request: "device_import",
  //         request_parameters: exportDevicePayload,
  //       });
  //       requestType = "modbus";
  //     } else if (deviceType === "CIM-Cloud" || deviceType === "MQTT") {
  //       const selectedtransport = {
  //         devicename: response.data.device_name,
  //         protocol: deviceType,
  //       };

  //       configurationPayload.export_device.selectedtransport = [selectedtransport];

  //       const exportDevicePayload = configurationPayload.export_device;
  //       payload = JSON.stringify({
  //         request: "transport_import",
  //         request_parameters: exportDevicePayload,
  //       });
  //       requestType = "cloud";
  //     } else {
  //       console.log("Standard device type, using 'set_config' request type");

  //       payload = JSON.stringify({
  //         request: "set_config",
  //         request_parameters: configurationPayload,
  //       });
  //     }

  //     console.log("Final Payload:", payload);

  //     const mqttBrokerUrl = `mqtt://${selectedDevice.ip_address}:9001`;
  //     console.log("mqtt IP set dynamically: ", mqttBrokerUrl);
  //     const client = mqtt.connect(mqttBrokerUrl);

  //     client.on("connect", () => {
  //       console.log("MQTT client connected");

  //       client.publish(mqtt_topic, payload, (error) => {
  //         if (error) {
  //           console.error("Error publishing to MQTT:", error.message);
  //           setPushConfigSnackbar({
  //             open: true,
  //             message: "Failed to publish configuration.",
  //           });
  //         } else {
  //           console.log("Configuration published successfully!");
  //           setPushConfigSnackbar({
  //             open: true,
  //             message:
  //               "Your configuration has been updated. Please check iEdge360 portal of your CIM10.",
  //           });

  //           if (requestType === "modbus") {
  //             const refreshPayload = JSON.stringify({
  //               request: "get_device_config",
  //               request_parameters: "ALL",
  //             });

  //             client.publish("service_device/service_get_refresh:QT", refreshPayload, (error) => {
  //               if (error) {
  //                 console.error("Error publishing get_device_config:", error.message);
  //                 setPushConfigSnackbar({
  //                   open: true,
  //                   message: "Failed to request device configuration.",
  //                 });
  //               } else {
  //                 console.log("Request for device configuration published successfully!");
  //               }
  //             });

  //             client.subscribe("service_get_refresh/#", (err) => {
  //               if (err) {
  //                 console.error("Error subscribing to service_get_refresh/#:", err.message);
  //                 setPushConfigSnackbar({
  //                   open: true,
  //                   message: "Failed to subscribe to device configuration updates.",
  //                 });
  //               } else {
  //                 console.log("Subscribed to service_get_refresh/# successfully!");
  //               }
  //             });
  //           }

  //           if (requestType === "cloud") {
  //             const refreshPayload = JSON.stringify({
  //               request: "get_transport_config",
  //               request_parameters: "ALL",
  //             });

  //             client.publish("service_transport/service_webui:QT", refreshPayload, (error) => {
  //               if (error) {
  //                 console.error("Error publishing get_device_config:", error.message);
  //                 setPushConfigSnackbar({
  //                   open: true,
  //                   message: "Failed to request device configuration.",
  //                 });
  //               } else {
  //                 console.log("Request for device configuration published successfully!");
  //               }
  //             });

  //             client.subscribe("service_webui/#", (err) => {
  //               if (err) {
  //                 console.error("Error subscribing to service_webui/#:", err.message);
  //               } else {
  //                 console.log("Subscribed to service_webui/# successfully!");
  //               }
  //             });
  //           }
  //         }
  //       });
  //     });

  //     client.on("message", (topic, message) => {
  //       console.log(`Message received on topic ${topic}:`, message.toString());

  //       if (topic.startsWith("service_get_refresh/")) {
  //         try {
  //           const data = JSON.parse(message.toString());
  //           console.log("Parsed data from service_get_refresh:", data);

  //           // Check if device_config exists and is an array
  //           if (data.device_config && Array.isArray(data.device_config)) {
  //             console.log("Device configuration found:", data.device_config);

  //             // Loop through each device in device_config
  //             data.device_config.forEach((device) => {
  //               const { topic, devicename, protocol } = device;

  //               // Log device details
  //               console.log(`Processing device - Name: ${devicename}, Topic: ${topic}, Protocol: ${protocol}`);

  //               // Check if topic, devicename, and protocol exist
  //               if (topic && devicename && protocol) {
  //                 console.log("Device has all required properties (topic, devicename, protocol).");

  //                 // Check if the current device matches the response data
  //                 if (response.data.device_name === device.devicename && response.data.device_type === device.protocol) {
  //                   console.log("Device matches response data (device_name and protocol).");

  //                   // If the device is a Modbus RTU device
  //                   if (response.data.device_type === "modbusrtu") {
  //                     console.log("Device type is modbusrtu.");

  //                     // Construct the access topic for ModbusSerial and create the payload request
  //                     const access_topic = `${device.topic}/service_webui_ModbusSerial:QT`;
  //                     const getModbusPayloadRequest = JSON.stringify({
  //                       request: "get_config",
  //                       request_parameters: "ALL",
  //                     });

  //                     // Log access topic and payload
  //                     console.log("Publishing get_config request to topic:", access_topic);
  //                     console.log("Payload for ModbusSerial request:", getModbusPayloadRequest);

  //                     // Publish the request to the ModbusSerial topic
  //                     client.publish(access_topic, getModbusPayloadRequest, (error) => {
  //                       if (error) {
  //                         console.error("Error publishing get_config request for ModbusSerial:", error.message);
  //                         setPushConfigSnackbar({
  //                           open: true,
  //                           message: "Failed to request device configuration.",
  //                         });
  //                       } else {
  //                         console.log("Request for ModbusSerial device configuration published successfully!");
  //                       }
  //                     });

  //                     // Subscribe to the response topic for ModbusSerial
  //                     client.subscribe("service_webui_ModbusSerial/#", (err) => {
  //                       if (err) {
  //                         console.error("Error subscribing to service_webui_ModbusSerial topic:", err.message);
  //                       } else {
  //                         console.log("Subscribed to service_webui_ModbusSerial/# successfully.");
  //                       }
  //                     });

  //                     console.log("starting and calling on message function and reading the data on the topic service_webui_ModbusSerial/#" )

  //                     const stopic = "service_webui_ModbusSerial/#"

  //                     // Handle incoming messages on the subscribed topic dynamically
  //                     client.on("message", (topic, message) => {
  //                       // Log the received topic and message
  //                       console.log(`Message received on topic service_webui_ModbusSerial/# ${topic}:`, message.toString());

  //                       // Only process messages from the "service_webui_ModbusSerial/#" topic
  //                       if (topic.startsWith("service_webui_ModbusSerial/")) {
  //                         console.log("Processing message from service_webui_ModbusSerial topic.");

  //                         try {
  //                           // Try parsing the message into JSON
  //                           const data = JSON.parse(message.toString());
  //                           console.log("Parsed data for service_webui_ModbusSerial:", data);

  //                           // Check if the data contains the expected fields
  //                           if (data.config) {
  //                             const { config } = data;
  //                             console.log("Configuration data:", config);

  //                             // Example: Print the config details
  //                             console.log("Publisher Details:", config.publisher);
  //                             console.log("TCP/IP Port:", config.tcpip_port);
  //                             console.log("Serial Port Configuration:", config.serial_port);
  //                             console.log("Enable Flag:", config.enable);
  //                             console.log("Queries:", config.query);
  //                             console.log("Device Name:", config.devicename);

  //                             // Process queries if they exist
  //                             if (Array.isArray(config.query)) {
  //                               config.query.forEach((query, index) => {
  //                                 console.log(`Processing query ${index + 1}:`, query);

  //                                 if (Array.isArray(query.parameter)) {
  //                                   query.parameter.forEach((param, paramIndex) => {
  //                                     console.log(`Parameter ${paramIndex + 1}:`, param);
  //                                   });
  //                                 } else {
  //                                   console.log("No parameters found in the query.");
  //                                 }
  //                               });
  //                             } else {
  //                               console.log("No queries found in the configuration.");
  //                             }
  //                           } else {
  //                             console.log("No config found in the parsed data.");
  //                           }
  //                         } catch (error) {
  //                           // Log the error if JSON parsing fails
  //                           console.error("Error parsing JSON response for ModbusSerial:", error.message);
  //                         }
  //                       } else {
  //                         // Log if the message is received on a different topic
  //                         console.log(`Message received on a non-ModbusSerial topic: ${topic}, ignoring.`);
  //                       }
  //                     });

  //                   // If the device is a Modbus TCP/IP device
  //                   } else if (response.data.device_type === "modbustcpip") {
  //                     console.log("Device type is modbustcpip.");

  //                     // Construct the access topic for ModbusTCP and create the payload request
  //                     const access_topic = `${device.topic}/service_webui_ModbusTCP:QT`;
  //                     const getModbusPayloadRequest = JSON.stringify({
  //                       request: "get_config",
  //                       request_parameters: "ALL",
  //                     });

  //                     // Log access topic and payload
  //                     console.log("Publishing get_config request to topic:", access_topic);
  //                     console.log("Payload for ModbusTCP request:", getModbusPayloadRequest);

  //                     // Publish the request to the ModbusTCP topic
  //                     client.publish(access_topic, getModbusPayloadRequest, (error) => {
  //                       if (error) {
  //                         console.error("Error publishing get_config request for ModbusTCP:", error.message);
  //                         setPushConfigSnackbar({
  //                           open: true,
  //                           message: "Failed to request device configuration.",
  //                         });
  //                       } else {
  //                         console.log("Request for ModbusTCP device configuration published successfully!");
  //                       }
  //                     });

  //                     // Subscribe to the response topic for ModbusTCP
  //                     client.subscribe("service_webui_ModbusTCP/#", (err) => {
  //                       if (err) {
  //                         console.error("Error subscribing to service_webui_ModbusTCP topic:", err.message);
  //                       } else {
  //                         console.log("Subscribed to service_webui_ModbusTCP/# successfully.");
  //                       }
  //                     });
  //                   }
  //                 } else {
  //                   console.log("Device does not match the response data (device_name and protocol).");
  //                 }
  //               } else {
  //                 console.log("Device is missing one or more properties (topic, devicename, protocol).");
  //               }
  //             });
  //           } else {
  //             console.log("No device_config array found in the response.");
  //           }
  //         } catch (error) {
  //           console.error("Error parsing JSON response:", error.message);
  //         }
  //       }

  //       if (topic.startsWith("service_webui/")) {
  //         try {
  //           const data = JSON.parse(message.toString());
  //           console.log("Parsed data for service_webui:", data);

  //           if (data.transport_config && Array.isArray(data.transport_config)) {
  //             data.transport_config.forEach((device) => {
  //               const { topic, devicename, protocol, deviceid } = device;
  //               if (topic && devicename && protocol && deviceid) {
  //                 console.log(`Device ID: ${deviceid}, Device Name: ${devicename}, Topic: ${topic}, Protocol: ${protocol}`);
  //               } else {
  //                 console.log("Device is missing topic, device name, protocol, or device ID.");
  //               }
  //             });
  //           } else {
  //             console.log("No transport_config array found in the response for service_webui.");
  //           }
  //         } catch (error) {
  //           console.error("Error parsing JSON response for service_webui:", error.message);
  //         }
  //       }
  //     });

  //     client.on("error", (err) => {
  //       console.error("MQTT client error:", err);
  //       setPushConfigSnackbar({ open: true, message: "MQTT client error." });
  //     });
  //   } catch (error) {
  //     console.error("Error in uploadConfig function:", error.message);
  //     if (error.response) {
  //       console.error("Response data:", error.response.data);
  //     }
  //     setPushConfigSnackbar({
  //       open: true,
  //       message: "Error fetching or uploading configuration.",
  //     });
  //   }
  // };

  const uploadConfig = async (conversationId, setPushConfigSnackbar) => {
    console.log("Starting uploadConfig function");

    try {
      console.log(
        `Fetching configuration for conversationId: ${conversationId}`,
      );

      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/get_latest_device_config/${conversationId}/`,
        {
          headers: {},
        },
      );

      console.log("Response received from API:", response.data);

      const configurationPayload = response.data.configuration_payload;
      console.log("Configuration Payload:", configurationPayload);

      const deviceType = response.data.device_type.toLowerCase();
      console.log("Device Type:", deviceType);

      const mqtt_topic = mqtt_topics[deviceType];
      console.log("Determined MQTT Topic:", mqtt_topic);

      if (!mqtt_topic) {
        console.error(`Unknown device type: ${deviceType}`);
        setPushConfigSnackbar({
          open: true,
          message: `Unknown device type: ${deviceType}`,
        });
        return;
      }

      let payload;
      let requestType;

      // Apply custom order for modbusrtu and modbustcpip
      if (deviceType === "modbusrtu" || deviceType === "modbustcpip") {
        console.log("Device type requires 'device_import' request type");

        const selectedDevice = {
          address: "",
          protocol: deviceType,
          devicename: response.data.device_name,
        };

        configurationPayload.export_device = orderJsonKeys(
          configurationPayload.export_device,
          deviceType,
        );
        configurationPayload.export_device.selecteddevice = [selectedDevice];

        payload = JSON.stringify({
          request: "device_import",
          request_parameters: configurationPayload.export_device,
        });
        requestType = "modbus";
      } else if (deviceType === "cim-cloud" || deviceType === "mqtt") {
        const selectedTransport = {
          devicename: response.data.device_name,
          protocol: response.data.device_type,
        };

        configurationPayload.export_transport.selectedtransport = [
          selectedTransport,
        ];

        payload = JSON.stringify({
          request: "transport_import",
          request_parameters: configurationPayload.export_transport,
        });
        requestType = "cloud";
      } else if (deviceType === "ethernet") {
        console.log("Ethernet condition triggered");

        // Structure the configuration payload for Ethernet
        const structuredConfig = orderJsonKeys(
          configurationPayload,
          deviceType,
        );
        console.log(
          "Structured Ethernet Config:",
          JSON.stringify(structuredConfig),
        );

        // Prepare the final payload
        payload = JSON.stringify(structuredConfig);
        requestType = "ethernet";
      } else if (deviceType === "modem") {
        console.log("modem");
        const structuredConfig = orderJsonKeys(
          configurationPayload,
          deviceType,
        );
        payload = JSON.stringify(structuredConfig);
        requestType = "modem";
      } else {
        console.log("Standard device type, using 'set_config' request type");

        payload = JSON.stringify({
          request: "set_config",
          request_parameters: configurationPayload,
        });
        if( deviceType === "analog"){
        sessionStorage.setItem(
          "analogPeripheralId",
          configurationPayload.ai_config.aiChannel[0].peripheral_id
        );
        sessionStorage.setItem(
          "analogPayload",
          JSON.stringify(configurationPayload)
        )
        }
        else if ( deviceType === "digital"){
          sessionStorage.setItem(
            "digitalPeripheralId",
            configurationPayload.di_config.DiChannel[0].peripheral_id
          );
          sessionStorage.setItem(
            "digitalPayload",
            JSON.stringify(configurationPayload)
          )
        }
        requestType = "standard";
      }

      console.log("Final Payload:", payload);
      console.log("Final Payload (Parsed):", JSON.parse(payload));

      const mqttBrokerUrl = `mqtt://${selectedDevice?.ip_address}:9001/`;
      console.log("MQTT IP set dynamically: ", mqttBrokerUrl);
      const client = mqtt.connect(mqttBrokerUrl);

      client.on("connect", () => {
        console.log("MQTT client connected");

        client.publish(mqtt_topic, payload, (error) => {
          if (error) {
            console.error("Error publishing to MQTT:", error.message);
            setPushConfigSnackbar({
              open: true,
              message: "Failed to publish configuration.",
            });
            client.end(); // Close connection on error
            return;
          }

          console.log("Configuration published successfully!");
          setPushConfigSnackbar({
            open: true,
            message: `Your configuration for device ${response.data.device_type} has been updated. Please check iEdge360 portal of your CIM10.`,
          });

          if (requestType === "modbus") {
            const responseTopic = "service_importdev/service_device:RT";
            console.log(`Subscribing to topic: ${responseTopic}`);

            client.subscribe(responseTopic, (err, granted) => {
              if (err) {
                console.error(
                  `Error subscribing to ${responseTopic}:`,
                  err.message,
                );
                // client.end(); // Close connection on error
                return;
              }
              console.log(`Successfully subscribed to topic ${responseTopic}`);
            });
            handleModbusRequest(client, setPushConfigSnackbar);
          } else if (requestType === "cloud") {
            handleCloudRequest(client, setPushConfigSnackbar);
          } else if (requestType === "ethernet" || requestType === "modem") {
            const networkTopic = "service_webui_network/#";
            console.log(`Subscribing to topic: ${networkTopic}`);

            client.subscribe(networkTopic, (err) => {
              if (err) {
                console.error(
                  `Error subscribing to ${networkTopic}:`,
                  err.message,
                );
                // client.end(); // Close connection on error
                return;
              }
              console.log(`Successfully subscribed to topic ${networkTopic}`);
            });
          } else if (requestType === "standard") {
            console.log(
              "No additional handling required for standard configuration.",
            );
            client.end(); // Close connection after standard request
          }
        });
      });

      client.on("message", (topic, message) => {
        console.log(`Message received on topic ${topic}:`, message.toString());

        if (topic.startsWith("service_get_refresh/")) {
          handleGetRefreshResponse(
            topic,
            message,
            response,
            client,
            setPushConfigSnackbar,
          );
        } else if (topic.startsWith("service_webui/")) {
          handleWebUIResponse(
            topic,
            message,
            response,
            client,
            setPushConfigSnackbar,
          );
        }
        // client.end(); // Close connection after receiving the message
      });

      client.on("error", (err) => {
        console.error("MQTT client error:", err);
        setPushConfigSnackbar({ open: true, message: "MQTT client error." });
        client.end(); // Close connection on error
      });
    } catch (error) {
      console.error("Error in uploadConfig function:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
      setPushConfigSnackbar({
        open: true,
        message: "Error fetching or uploading configuration.",
      });
    }
  };

  const handleModbusRequest = (client, setPushConfigSnackbar) => {
    const refreshPayload = JSON.stringify({
      request: "get_device_config",
      request_parameters: "ALL",
    });

    client.publish(
      "service_device/service_get_refresh:QT",
      refreshPayload,
      (error) => {
        if (error) {
          console.error("Error publishing get_device_config:", error.message);
          setPushConfigSnackbar({
            open: true,
            message: "Failed to request device configuration.",
          });
          client.end(); // Close connection on error
        } else {
          console.log(
            "Request for device configuration published successfully!",
          );
        }
      },
    );

    client.subscribe("service_get_refresh/#", (err) => {
      if (err) {
        console.error(
          "Error subscribing to service_get_refresh/#:",
          err.message,
        );
        setPushConfigSnackbar({
          open: true,
          message: "Failed to subscribe to device configuration updates.",
        });
        client.end(); // Close connection on error
      } else {
        console.log("Subscribed to service_get_refresh/# successfully!");
        // Optional: Close the connection if no further actions are required
        // client.end();
      }
    });
  };

  const handleCloudRequest = (client, setPushConfigSnackbar) => {
    const refreshPayload = JSON.stringify({
      request: "get_transport_config",
      request_parameters: "ALL",
    });

    client.publish(
      "service_transport/service_webui:QT",
      refreshPayload,
      (error) => {
        if (error) {
          console.error(
            "Error publishing get_transport_config:",
            error.message,
          );
          setPushConfigSnackbar({
            open: true,
            message: "Failed to request transport configuration.",
          });
          client.end(); // Close connection on error
        } else {
          console.log(
            "Request for transport configuration published successfully!",
          );
        }
      },
    );

    client.subscribe("service_webui/#", (err) => {
      if (err) {
        console.error("Error subscribing to service_webui/#:", err.message);
        client.end(); // Close connection on error
      } else {
        console.log("Subscribed to service_webui/# successfully!");
        // Optional: Close the connection if no further actions are required
        // client.end();
      }
    });
  };

  const handleGetRefreshResponse = (
    topic,
    message,
    response,
    client,
    setPushConfigSnackbar,
  ) => {
    try {
      const data = JSON.parse(message.toString());
      console.log("Parsed data from service_get_refresh:", data);
  
      if (data.device_config && Array.isArray(data.device_config)) {
        console.log("Device configuration found:", data.device_config);
  
        data.device_config.forEach((device) => {
          const { topic, devicename, protocol } = device;
  
          if (topic && devicename && protocol) {
            console.log(
              "Device has all required properties (topic, devicename, protocol).",
            );
  
            if (
              response.data.device_name === devicename &&
              response.data.device_type === protocol
            ) {
              if (protocol === "modbusrtu") {
                handleModbusRTURequest(client, device, setPushConfigSnackbar);
              } else if (protocol === "modbustcpip") {
                handleModbusTCPIPRequest(client, device, setPushConfigSnackbar);
              }
            } else {
              console.log(
                "Device does not match the response data (device_name and protocol).",
              );
            }
          } else {
            console.log(
              "Device is missing one or more properties (topic, devicename, protocol).",
            );
          }
        });
      } else {
        console.log("No device_config array found in the response.");
      }
    } catch (error) {
      console.error("Error parsing JSON response:", error.message);
    }
  };
  

  const handleModbusRTURequest = (
    client,
    device,
    setPushConfigSnackbar,
    // appName,
  ) => {
    const ptopic = `${device.topic}/service_webui_ModbusSerial:QT`;
    const stopic = `service_webui_ModbusSerial/${device.topic}:RT`;
    const getModbusPayloadRequest = JSON.stringify({
      request: "get_config",
      request_parameters: "ALL",
    });

    // Publish the request to get ModbusSerial device configuration
    client.publish(ptopic, getModbusPayloadRequest, (error) => {
      if (error) {
        console.error(
          "Error publishing get_config request for ModbusSerial:",
          error.message,
        );
        setPushConfigSnackbar({
          open: true,
          message: "Failed to request ModbusSerial device configuration.",
        });
        client.end(); // Close connection if publishing fails
      } else {
        console.log(
          "Request for ModbusSerial device configuration published successfully!",
        );
      }
    });

    // Subscribe to the response topic
    client.subscribe(stopic, (err, granted) => {
      if (err) {
        console.error(
          "Error subscribing to service_webui_ModbusSerial topic:",
          err.message,
        );
        client.end(); // Close connection if subscription fails
      } else {
        console.log(`Successfully subscribed to topic ${stopic}`);
        console.log("Subscription granted:", granted);
      }
    });

    // Listen for incoming messages on the subscribed topic
    client.on("message", (topic, message) => {
      // Check if the message is from the expected topic
      if (topic === stopic) {
        console.log(`Message received on stopic ${topic}:`, message.toString());

        try {
          // Parse the message as JSON
          const data = JSON.parse(message.toString());
          console.log("Parsed data from ModbusSerial message:", data);

          // Handle the received data - store in modbusConfig state
          setModbusrtuConfig(data.config);
          console.log("Set modbus device config", data.config);
          sessionStorage.setItem("modbusrtuconfig", JSON.stringify(data.config))
          // sessionStorage.setItem("modbusDeviceId", data.config.query.map(query => query.peripheral_id))
          sessionStorage.setItem("modbusDeviceId", data.config.query[0].peripheral_id)
          sessionStorage.setItem("modbusrtuDeviceTopic", device.topic)

          // const modbusAppName = device.topic;
          // CIMCloudConfig()
          // handleCIMCloudRequest(client, device, setPushConfigSnackbar, modbusAppName);


          // const updatedModbusPayload = updateModbusPayloadWithAppName(
          //   data,
          //   // appName,
          // );

          // // Store in state if required
          // // setModbusrtuConfig(updatedModbusPayload);
          // console.log(
          //   "Set Modbus device config with updated appName",
          //   updatedModbusPayload,
          // );

          // const postUpdatedPayload = JSON.stringify({
          //   request: "set_config",
          //   config: updatedModbusPayload.config, // Send the updated configuration
          // });

          // // Publish the updated Modbus configuration back to the same topic
          // client.publish(ptopic, postUpdatedPayload, (error) => {
          //   if (error) {
          //     console.error(
          //       "Error publishing updated ModbusSerial configuration:",
          //       error.message,
          //     );
          //     setPushConfigSnackbar({
          //       open: true,
          //       message: "Failed to post updated ModbusSerial configuration.",
          //     });
          //   } else {
          //     console.log(
          //       "Updated ModbusSerial configuration posted successfully!",
          //     );
          //     setPushConfigSnackbar({
          //       open: true,
          //       message:
          //         "Updated ModbusSerial configuration posted successfully!",
          //     });
          //   }
          // });

          // setPushConfigSnackbar({
          //   open: true,
          //   message:
          //     "ModbusSerial device configuration received and updated successfully!",
          // });

          // Notify the user that the configuration was received successfully
          setPushConfigSnackbar({
            open: true,
            message:
              "ModbusSerial device configuration received successfully!",
          });

          // Close the MQTT connection after the configuration has been processed
          client.end();
        } catch (error) {
          console.error(
            "Error parsing JSON from ModbusSerial message:",
            error.message,
          );
          console.log("Raw message received:", message.toString());

          // Close connection on error
          client.end();
        }
      } else {
        console.log(`Message received from another topic: ${topic}`);
      }
    });
  };

  const handleModbusTCPIPRequest = (client, device, setPushConfigSnackbar) => {
    const access_topic = `${device.topic}/service_webui_ModbusTCP:QT`;
    const stopic = `service_webui_ModbusTCP/${device.topic}:RT`;
    const getModbusPayloadRequest = JSON.stringify({
      request: "get_config",
      request_parameters: "ALL",
    });

    // Publish the request to get ModbusTCP device configuration
    client.publish(access_topic, getModbusPayloadRequest, (error) => {
      if (error) {
        console.error(
          "Error publishing get_config request for ModbusTCP:",
          error.message,
        );
        setPushConfigSnackbar({
          open: true,
          message: "Failed to request ModbusTCP device configuration.",
        });
        client.end(); // Close connection on publish failure
      } else {
        console.log(
          "Request for ModbusTCP device configuration published successfully!",
        );
      }
    });

    // Subscribe to the response topic
    client.subscribe(stopic, (err, granted) => {
      if (err) {
        console.error(
          "Error subscribing to service_webui_ModbusTCP topic:",
          err.message,
        );
        client.end(); // Close connection on subscribe failure
      } else {
        console.log(`Successfully subscribed to topic ${stopic}`);
        console.log("Subscription granted:", granted);
      }
    });

    // Listen for incoming messages on the subscribed topic
    client.on("message", (topic, message) => {
      // Check if the message is from the expected topic
      if (topic === stopic) {
        console.log(`Message received on stopic ${topic}:`, message.toString());

        try {
          // Parse the message as JSON
          const data = JSON.parse(message.toString());
          console.log("Parsed data from ModbusTCP message:", data);

          // Handle the received data - store in modbusConfig state

          setModbustcpipConfig(data.config);
          console.log("Set modbusTCPIP device config", data.config);
          sessionStorage.setItem("modbustcpipConfig", JSON.stringify(data.config))
          // sessionStorage.setItem("modbusDeviceId", data.config.query.map(query => query.peripheral_id))
          sessionStorage.setItem("modbustcpipDeviceId", data.config.query[0].peripheral_id)
          sessionStorage.setItem("modbustcpipDeviceTopic", device.topic)

          // Notify the user that the configuration was received successfully
          setPushConfigSnackbar({
            open: true,
            message: "ModbusTCP device configuration received successfully!",
          });

          // Close the MQTT connection after the configuration has been processed
          client.end();
        } catch (error) {
          console.error(
            "Error parsing JSON from ModbusTCP message:",
            error.message,
          );
          console.log("Raw message received:", message.toString());

          // Close connection on JSON parsing error
          client.end();
        }
      } else {
        console.log(`Message received from another topic: ${topic}`);
      }
    });
  };

  const handleWebUIResponse = (
    topic,
    message,
    response,
    client,
    setPushConfigSnackbar,
  ) => {
    try {
      const data = JSON.parse(message.toString());
      console.log("Parsed data from service_webui:", data);

      if (data.transport_config) {
        console.log("Transport configuration received:", data.transport_config);

        // Example: Further processing of transport_config data
        // If the transport config has necessary fields, you can process them here
        data.transport_config.forEach((config) => {
          if (config.protocol && config.topic && config.devicename) {
            console.log(
              `Protocol: ${config.protocol}, topic: ${config.topic}, and device_name: ${config.devicename}`,
            );

            // Check if response data matches transport config
            if (
              response.data.device_name === config.devicename &&
              response.data.device_type === config.protocol
            ) {
              console.log("hello");

              // Assuming protocol is defined somewhere
              if (config.protocol === "CIM-Cloud") {
                console.log("cimcloud");
                handleCIMCloudRequest(client, config, setPushConfigSnackbar); // Assuming `device` refers to response.data here
                // client.end(); // End client connection
              } else if (config.protocol === "MQTT") {
                console.log("MQTT");
                handleMQTTCloudRequest(client, config, setPushConfigSnackbar);
              }
            }
          } else {
            console.log("Transport config is missing protocol or endpoint.");
          }
        });
      } else {
        console.log("No transport configuration found in the response.");
      }
    } catch (error) {
      console.error(
        "Error parsing JSON response from service_webui:",
        error.message,
      );
      console.log("Raw message received:", message.toString());
      client.end();
    }
  };

  const handleCIMCloudRequest = (client, device, setPushConfigSnackbar) => {
    // Dynamic topic based on device information
    const ptopic = `${device.topic}/service_webui_Thingsboard:QT`; 
    const stopic = `service_webui_Thingsboard/${device.topic}:RT`;
    const getCIMCloudPayloadRequest = JSON.stringify({
      request: "get_config",
      request_parameters: "ALL",
    });

    // Publish the request to get CIMCloud device configuration
    client.publish(ptopic, getCIMCloudPayloadRequest, (error) => {
      if (error) {
        console.error(
          "Error publishing get_config request for CIMCloud:",
          error.message,
        );
        setPushConfigSnackbar({
          open: true,
          message: "Failed to request CIMCloud device configuration.",
        });
        client.end(); // Close connection if publishing fails
      } else {
        console.log(
          "Request for CIMCloud device configuration published successfully!",
        );
      }
    });

    // Subscribe to the response topic
    client.subscribe(stopic, (err, granted) => {
      if (err) {
        console.error(
          "Error subscribing to CIMCloud response topic:",
          err.message,
        );
        client.end(); // Close connection if subscription fails
      } else {
        console.log(`Successfully subscribed to topic ${stopic}`);
        console.log("Subscription granted:", granted);
      }
    });

    // Listen for incoming messages on the subscribed topic
    client.on("message", (topic, message) => {
      // Check if the message is from the expected topic
      if (topic === stopic) {
        console.log(`Message received on stopic ${topic}:`, message.toString());

        try {
          // Parse the message as JSON
          const data = JSON.parse(message.toString());
          console.log("Parsed data from CIMCloud message:",JSON.stringify(data));

          // Handle the received data - store in the relevant state
          // setCIMCloudConfig(data.config); // Assuming you have a state setter for CIMCloud config
          // console.log("Set CIMCloud device config", data);
          sessionStorage.setItem("cimCloudConfig", JSON.stringify(data));
          sessionStorage.setItem("cimCloudDeviceTopic", device.topic);
          // sessionStorage.removeItem("cimcloudconfig")
          // const modbusDeviceId = sessionStorage.getItem("modbusDeviceId");
          // const modbusrtuDeviceTopic = sessionStorage.getItem("modbusrtuDeviceTopic");
          updateAndRepostCIMCloudPayload(client, device, setPushConfigSnackbar);
          updateAndRepostModbusRTUPayload(client, device, setPushConfigSnackbar);
          updateAndRepostModbusTCPIPPayload(client, device, setPushConfigSnackbar);

          sessionStorage.removeItem("cimCloudConfig");
          sessionStorage.removeItem("cimCloudDeviceTopic");
          sessionStorage.removeItem("modbusDeviceId");
          sessionStorage.removeItem("modbusrtuDeviceTopic");
          sessionStorage.removeItem("modbusrtuconfig");
          sessionStorage.removeItem("modbustcpipConfig");
          sessionStorage.removeItem("modbustcpipDeviceId");
          sessionStorage.removeItem("modbustcpipDeviceTopic");
          
          
          

          // Notify the user that the configuration was received successfully
          setPushConfigSnackbar({
            open: true,
            message: "CIMCloud device configuration received successfully!",
          });

          // Close the MQTT connection after the configuration has been processed
          client.end();
        } catch (error) {
          console.error(
            "Error parsing JSON from CIMCloud message:",
            error.message,
          );
          console.log("Raw message received:", message.toString());

          // Close connection on error
          client.end();
        }
      } else {
        console.log(`Message received from another topic: ${topic}`);
      }
    });
  };

  const handleMQTTCloudRequest = (client, device, setPushConfigSnackbar) => {
    // Dynamic topic based on device information
    const ptopic = `${device.topic}/service_webui_Thingsboard:QT`;
    const stopic = `service_webui_Thingsboard/${device.topic}:RT`;
    const getMQTTCloudPayloadRequest = JSON.stringify({
      request: "get_config",
      request_parameters: "ALL",
    });

    // Publish the request to get MQTTCloud device configuration
    client.publish(ptopic, getMQTTCloudPayloadRequest, (error) => {
      if (error) {
        console.error(
          "Error publishing get_config request for MQTTCloud:",
          error.message,
        );
        setPushConfigSnackbar({
          open: true,
          message: "Failed to request MQTTCloud device configuration.",
        });
        client.end(); // Close connection if publishing fails
      } else {
        console.log(
          "Request for MQTTCloud device configuration published successfully!",
        );
      }
    });

    // Subscribe to the response topic
    client.subscribe(stopic, (err, granted) => {
      if (err) {
        console.error(
          "Error subscribing to MQTTCloud response topic:",
          err.message,
        );
        client.end(); // Close connection if subscription fails
      } else {
        console.log(`Successfully subscribed to topic ${stopic}`);
        console.log("Subscription granted:", granted);
      }
    });

    // Listen for incoming messages on the subscribed topic
    client.on("message", (topic, message) => {
      // Check if the message is from the expected topic
      if (topic === stopic) {
        console.log(`Message received on stopic ${topic}:`, message.toString());

        try {
          // Parse the message as JSON
          const data = JSON.parse(message.toString());
          console.log("Parsed data from MQTTCloud message:", data);

          // Handle the received data - store in the relevant state
          setMQTTCloudConfig(data); // Assuming you have a state setter for MQTTCloud config
          console.log("Set MQTTCloud device config", data);
          sessionStorage.setItem("mqttCloudConfig", JSON.stringify(data));
          sessionStorage.setItem("mqttCloudDeviceTopic", device.topic);

          // Notify the user that the configuration was received successfully
          setPushConfigSnackbar({
            open: true,
            message: "MQTTCloud device configuration received successfully!",
          });

          // Close the MQTT connection after the configuration has been processed
          client.end();
        } catch (error) {
          console.error(
            "Error parsing JSON from MQTTCloud message:",
            error.message,
          );
          console.log("Raw message received:", message.toString());

          // Close connection on error
          client.end();
        }
      } else {
        console.log(`Message received from another topic: ${topic}`);
      }
    });
  };

  const updateAndRepostModbusRTUPayload = (client, device, setPushConfigSnackbar) => {
    try {
      console.log("Starting updateAndRepostModbusRTUPayload function...");
  
      // Retrieve modbusrtuConfig and cimCloudDeviceTopic from session storage
      const modbusrtuConfig = JSON.parse(sessionStorage.getItem("modbusrtuconfig"));
      const cimCloudDeviceTopic = sessionStorage.getItem("cimCloudDeviceTopic");
      const modbusrtuDeviceTopic = sessionStorage.getItem("modbusrtuDeviceTopic");
  
      console.log("Retrieved modbusrtuConfig from session storage:", modbusrtuConfig);
      console.log("Retrieved cimCloudDeviceTopic from session storage:", cimCloudDeviceTopic);
  
      // Verify necessary data is available
      if (!modbusrtuConfig || !cimCloudDeviceTopic) {
        console.error("Missing modbusrtuConfig or cimCloudDeviceTopic in session storage");
        return;
      }
  
      console.log("Data verification passed, proceeding with updating modbusrtuConfig...");
  
      // Check if destination array exists in publisher
      if (!Array.isArray(modbusrtuConfig.publisher.destination)) {
        console.log("Destination array does not exist in publisher. Initializing a new one...");
        modbusrtuConfig.publisher.destination = [];
      }
  
      // Add the cimCloudDeviceTopic to the destination array
     
  
      // Add new destination to the modbusrtuConfig publisher destination array
      modbusrtuConfig.publisher.destination.push(cimCloudDeviceTopic);

      const updatedModbusrtuConfig = {config: modbusrtuConfig};
  
      console.log("Updated publisher destination array:", modbusrtuConfig.publisher.destination);
  
      // Prepare the updated payload for posting
      const updatedModbusPayload = JSON.stringify({
        request: "set_config",
        request_parameters: updatedModbusrtuConfig, // Send the updated modbus configuration
      });
  
      console.log("Prepared updated Modbus RTU payload:", updatedModbusPayload);
  
      // Define the topics
      const ptopic = `${modbusrtuDeviceTopic}/service_webui_ModbusSerial:QT`;
      const stopic = `service_webui_ModbusSerial/${modbusrtuDeviceTopic}:RT`;
  
      console.log(`Publishing updated payload to topic ${ptopic}...`);
  
      // Publish the updated Modbus configuration
      client.publish(ptopic, updatedModbusPayload, (error) => {
        if (error) {
          console.error("Error posting updated Modbus RTU configuration:", error.message);
          setPushConfigSnackbar({
            open: true,
            message: "Failed to post updated Modbus RTU configuration.",
          });
        } else {
          console.log("Updated Modbus RTU configuration posted successfully!");
          setPushConfigSnackbar({
            open: true,
            message: "Updated Modbus RTU configuration posted successfully!",
          });
  
          console.log(`Subscribing to response topic ${stopic} to verify payload...`);
  
          // Subscribe to the response topic to verify the payload
          client.subscribe(stopic, (err, granted) => {
            if (err) {
              console.error("Error subscribing to Modbus RTU response topic:", err.message);
              client.end(); // Close connection if subscription fails
            } else {
              console.log(`Successfully subscribed to topic ${stopic}`);
              console.log("Subscription granted:", granted);
            }
          });
  
          // Listen for verification of the updated configuration
          client.on("message", (topic, message) => {
            console.log(`Received message on topic ${topic}:`, message.toString());
            if (topic === stopic) {
              console.log(`Message received on stopic ${topic}:`, message.toString());
              try {
                const response = JSON.parse(message.toString());
                console.log("Verified updated Modbus RTU configuration:", response);
                setPushConfigSnackbar({
                  open: true,
                  message: "Modbus RTU configuration updated and verified successfully!",
                });
                client.end(); // Close the connection after success
              } catch (error) {
                console.error("Error parsing verification message:", error.message);
                client.end(); // Close connection on error
              }
            } else {
              console.log(`Message received from another topic: ${topic}`);
            }
          });
        }
      });
    } catch (error) {
      console.error("Error updating and reposting Modbus RTU payload:", error.message);
    }
  };


  const updateAndRepostPeripheralPayload = (client, deviceType, setPushConfigSnackbar) => {
    try {
      console.log("Starting updateAndRepostPeripheralPayload function...");
  
      // Retrieve necessary payloads and topics from session storage based on device type
      let peripheralConfig, peripheralDeviceTopic, cimCloudDeviceTopic, ptopic, stopic;
  
      if (deviceType === "analog") {
        peripheralConfig = JSON.parse(sessionStorage.getItem("analogPayload"));
        peripheralDeviceTopic = sessionStorage.getItem("analogPeripheralId");
        ptopic = "service_analog_in/service_webui_PH:QT"
        stopic = "service_webui_PH/service_analog_in:RT"
      } else if (deviceType === "digital") {
        peripheralConfig = JSON.parse(sessionStorage.getItem("digitalPayload"));
        peripheralDeviceTopic = sessionStorage.getItem("digitalPeripheralId");
       
        ptopic =  "service_di_in/service_webui_PH:QT";
        stopic = "service_webui_PH/service_di_in:QT";
      }
  
      cimCloudDeviceTopic = sessionStorage.getItem("cimCloudDeviceTopic");
  
      console.log(`Retrieved peripheralConfig for ${deviceType}:`, peripheralConfig);
      console.log(`Retrieved cimCloudDeviceTopic from session storage:`, cimCloudDeviceTopic);
  
      // Verify necessary data is available
      if (!peripheralConfig || !peripheralDeviceTopic || !cimCloudDeviceTopic) {
        console.error("Missing peripheralConfig, peripheralDeviceTopic, or cimCloudDeviceTopic in session storage");
        return;
      }
  
      console.log(`Data verification passed for ${deviceType}, proceeding with updating peripheralConfig...`);
  
      // Check if destination array exists in publisher
      if (!Array.isArray(peripheralConfig.publisher.destination)) {
        console.log("Destination array does not exist in publisher. Initializing a new one...");
        peripheralConfig.publisher.destination = [];
      }
  
      // Add new destination to the peripheralConfig publisher destination array
      peripheralConfig.publisher.destination.push(cimCloudDeviceTopic);
  
      // const updatedPeripheralConfig = { config: peripheralConfig };
  
      console.log("Updated publisher destination array:", peripheralConfig.publisher.destination);
  
      // Prepare the updated payload for posting
      const updatedPeripheralPayload = JSON.stringify({
        request: "set_config",
        request_parameters: peripheralConfig, // Send the updated configuration
      });
  
      console.log(`Prepared updated ${deviceType} payload:`, updatedPeripheralPayload);
  
      // Define the publish and subscribe topics
      // const ptopic = `${peripheralDeviceTopic}/service_${deviceType}_in:QT`;
      // const stopic = `service_${deviceType}_in/${peripheralDeviceTopic}:RT`;
  
      console.log(`Publishing updated ${deviceType} payload to topic ${ptopic}...`);
  
      // Publish the updated peripheral configuration
      client.publish(ptopic, updatedPeripheralPayload, (error) => {
        if (error) {
          console.error(`Error posting updated ${deviceType} configuration:`, error.message);
          setPushConfigSnackbar({
            open: true,
            message: `Failed to post updated ${deviceType} configuration.`,
          });
        } else {
          console.log(`Updated ${deviceType} configuration posted successfully!`);
          setPushConfigSnackbar({
            open: true,
            message: `Updated ${deviceType} configuration posted successfully!`,
          });
  
          console.log(`Subscribing to response topic ${stopic} to verify payload...`);
  
          // Subscribe to the response topic to verify the payload
          client.subscribe(stopic, (err, granted) => {
            if (err) {
              console.error(`Error subscribing to ${deviceType} response topic:`, err.message);
              client.end(); // Close connection if subscription fails
            } else {
              console.log(`Successfully subscribed to topic ${stopic}`);
              console.log("Subscription granted:", granted);
            }
          });
  
          // Listen for verification of the updated configuration
          client.on("message", (topic, message) => {
            console.log(`Received message on topic ${topic}:`, message.toString());
            if (topic === stopic) {
              console.log(`Message received on stopic ${topic}:`, message.toString());
              try {
                const response = JSON.parse(message.toString());
                console.log(`Verified updated ${deviceType} configuration:`, response);
                setPushConfigSnackbar({
                  open: true,
                  message: `${deviceType} configuration updated and verified successfully!`,
                });
                client.end(); // Close the connection after success
              } catch (error) {
                console.error("Error parsing verification message:", error.message);
                client.end(); // Close connection on error
              }
            } else {
              console.log(`Message received from another topic: ${topic}`);
            }
          });
        }
      });
    } catch (error) {
      console.error(`Error updating and reposting ${deviceType} payload:`, error.message);
    }
  };
  
  


  const updateAndRepostCIMCloudPayload = (client, device, setPushConfigSnackbar) => {
    try {
      console.log("Starting updateAndRepostCIMCloudPayload function...");
  
      // Retrieve cloud payload and modbus info from session storage
      const cimCloudConfig = JSON.parse(sessionStorage.getItem("cimCloudConfig"));
      const modbusDeviceId = sessionStorage.getItem("modbusDeviceId");
      const modbusrtuDeviceTopic = sessionStorage.getItem("modbusrtuDeviceTopic");
  
      console.log("Retrieved cimCloudConfig from session storage:", cimCloudConfig);
      console.log("Retrieved modbusDeviceId from session storage:", modbusDeviceId);
      console.log("Retrieved modbusrtuDeviceTopic from session storage:", modbusrtuDeviceTopic);
  
      // Verify necessary data is available
      if (!cimCloudConfig || !modbusDeviceId || !modbusrtuDeviceTopic) {
        console.error("Missing CIMCloud config or Modbus info in session storage");
        return;
      }
  
      console.log("Data verification passed, proceeding with updating payload...");
  
      // Update the deviceid in the authentication array
      cimCloudConfig.authentication.forEach((auth) => {
        if (auth.authtype === 2) { // Assuming authtype 2 is the relevant one for Modbus
          console.log(`Updating deviceid for auth entry with authtype ${auth.authtype}`);
          auth.deviceid = modbusDeviceId;
        }
      });
  
      console.log("Updated authentication array with new deviceid:", cimCloudConfig.authentication);
  
      // Add a new object to the outgoing array
      const newOutgoingEntry = {
        id: modbusDeviceId, // Using the Modbus Device ID as the ID for this entry
        type: "Data",
        topic: "v1/devices/me/telemetry",
        appname: modbusrtuDeviceTopic, // Using the Modbus RTU device topic as the app name
      };
  
      console.log("Prepared new outgoing entry:", newOutgoingEntry);
  
      // Check if outgoing array exists, if not, initialize it
      if (!Array.isArray(cimCloudConfig.outgoing)) {
        console.log("Outgoing array does not exist. Initializing a new one...");
        cimCloudConfig.outgoing = [];
      }
  
      // Add the new outgoing entry to the array
      cimCloudConfig.outgoing.push(newOutgoingEntry);
  
      console.log("Updated outgoing array:", cimCloudConfig.outgoing);
  
      // Prepare the updated payload
      const updatedCloudPayload = JSON.stringify({
        request: "set_config",
        request_parameters: cimCloudConfig, // Send the updated cloud payload
      });
  
      console.log("Prepared updated cloud payload:", updatedCloudPayload);
  
      // Define the topics
      const ptopic = `${device.topic}/service_webui_Thingsboard:QT`;
      const stopic = `service_webui_Thingsboard/${device.topic}:RT`;
  
      console.log(`Publishing updated payload to topic ${ptopic}...`);
  
      // Publish the updated cloud configuration
      client.publish(ptopic, updatedCloudPayload, (error) => {
        if (error) {
          console.error("Error posting updated CIMCloud configuration:", error.message);
          setPushConfigSnackbar({
            open: true,
            message: "Failed to post updated CIMCloud configuration.",
          });
        } else {
          console.log("Updated CIMCloud configuration posted successfully!");
          setPushConfigSnackbar({
            open: true,
            message: "Updated CIMCloud configuration posted successfully!",
          });
  
          console.log(`Subscribing to response topic ${stopic} to verify payload...`);
  
          // Subscribe to the response topic to verify the payload
          client.subscribe(stopic, (err, granted) => {
            if (err) {
              console.error("Error subscribing to CIMCloud response topic:", err.message);
              client.end(); // Close connection if subscription fails
            } else {
              console.log(`Successfully subscribed to topic ${stopic}`);
              console.log("Subscription granted:", granted);
            }
          });
  
          // Listen for verification of the updated configuration
          client.on("message", (topic, message) => {
            console.log(`Received message on topic ${topic}:`, message.toString());
            if (topic === stopic) {
              console.log(`Message received on stopic ${topic}:`, message.toString());
              try {
                const response = JSON.parse(message.toString());
                console.log("Verified updated CIMCloud configuration:", response);
                setPushConfigSnackbar({
                  open: true,
                  message: "CIMCloud configuration updated and verified successfully!",
                });
                client.end(); // Close the connection after success
              } catch (error) {
                console.error("Error parsing verification message:", error.message);
                client.end(); // Close connection on error
              }
            } else {
              console.log(`Message received from another topic: ${topic}`);
            }
          });
        }
      });
    } catch (error) {
      console.error("Error updating and reposting CIMCloud payload:", error.message);
    }
  };
  
  

 



  const updateAndRepostModbusTCPIPPayload = (client, device, setPushConfigSnackbar) => {
    console.log("Starting updateAndRepostModbusTCPIPPayload function...");
  
    // Retrieve cimCloudDeviceTopic from session storage
    const cimCloudDeviceTopic = sessionStorage.getItem("cimCloudDeviceTopic");
    const modbustcpipConfig = sessionStorage.getItem("modbustcpipConfig");
    const modbustcpipDeviceTopic = sessionStorage.getItem("modbustcpipDeviceTopic");
    console.log("Retrieved cimCloudDeviceTopic from session storage:", cimCloudDeviceTopic);
  
    // Verify necessary data is available
    if (!cimCloudDeviceTopic) {
      console.error("Missing cimCloudDeviceTopic in session storage");
      return;
    }
  
    console.log("Data verification passed, proceeding with updating modbustcpipConfig...");
  
    // Check if destination array exists in publisher
    if (!Array.isArray(modbustcpipConfig.publisher.destination)) {
      console.log("Destination array does not exist in publisher. Initializing a new one...");
      modbustcpipConfig.publisher.destination = [];
    }
  
    // Add the cimCloudDeviceTopic to the destination array
    
    modbustcpipConfig.publisher.destination.push(cimCloudDeviceTopic);
    // Add new destination to the modbustcpipConfig publisher destination array
    // modbustcpipConfig.publisher.destination.push(newDestinationEntry);

    const updatedModbustcpipPayload = { config: modbustcpipConfig};


  
    console.log("Updated publisher destination array:", modbustcpipConfig.publisher.destination);
  
    // Prepare the updated payload, wrapping it inside the 'config' parameter
    const updatedModbusPayload = JSON.stringify({
      request: "set_config",
      config: updatedModbustcpipPayload, // Send the updated modbus configuration inside 'config'
    });
  
    console.log("Prepared updated Modbus TCP/IP payload:", updatedModbusPayload);
  
    // Define the topic to publish the updated payload
    const ptopic = `${modbustcpipDeviceTopic}/service_webui_ModbusTCP:QT`;
    console.log(`Publishing updated payload to topic ${ptopic}...`);
  
    // Publish the updated Modbus configuration
    client.publish(ptopic, updatedModbusPayload, (error) => {
      if (error) {
        console.error("Error posting updated Modbus TCP/IP configuration:", error.message);
        setPushConfigSnackbar({
          open: true,
          message: "Failed to post updated Modbus TCP/IP configuration.",
        });
      } else {
        console.log("Updated Modbus TCP/IP configuration posted successfully!");
        setPushConfigSnackbar({
          open: true,
          message: "Updated Modbus TCP/IP configuration posted successfully!",
        });
  
        console.log(`Subscribing to response topic ${stopic} to verify payload...`);
  
        // Subscribe to the response topic to verify the payload
        const stopic = `service_webui_ModbusTCP/${modbustcpipDeviceTopic}:RT`;
        client.subscribe(stopic, (err, granted) => {
          if (err) {
            console.error("Error subscribing to Modbus TCP/IP response topic:", err.message);
            client.end(); // Close connection if subscription fails
          } else {
            console.log(`Successfully subscribed to topic ${stopic}`);
            console.log("Subscription granted:", granted);
          }
        });
  
        // Listen for verification of the updated configuration
        client.on("message", (topic, message) => {
          console.log(`Received message on topic ${topic}:`, message.toString());
          if (topic === stopic) {
            console.log(`Message received on stopic ${topic}:`, message.toString());
            try {
              const response = JSON.parse(message.toString());
              console.log("Verified updated Modbus TCP/IP configuration:", response);
              setPushConfigSnackbar({
                open: true,
                message: "Modbus TCP/IP configuration updated and verified successfully!",
              });
              client.end(); // Close the connection after success
            } catch (error) {
              console.error("Error parsing verification message:", error.message);
              client.end(); // Close connection on error
            }
          } else {
            console.log(`Message received from another topic: ${topic}`);
          }
        });
      }
    });
  };


  const handlePushConfigClick = () => {
    uploadConfig(conversationId, setPushConfigSnackbar);
    // pushConfig(conversationId, setPushConfigSnackbar);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            borderRadius: 2,
            // width: "28%",
            position: "absolute", // Ensures absolute positioning for centering
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Centers the modal
            boxShadow: 24,
            width: "90%", // Responsive width for smaller screens
            maxWidth: "650px", // Set a max width for larger screens
            // overflow: "auto", // Enable scrolling if content overflows
            maxHeight: "90vh", // Prevents the modal from exceeding the viewport height
          }}
        >
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              sx={{
                fontSize: "2rem", // Adjust font size as needed
                fontWeight: "bold", // Make the font bold
                color: "text.primary", // Use primary text color from the theme
                marginBottom: "0.5rem", // Add space below the label
                display: "block", // Ensure the label takes up full width
                // Add additional styles as needed
              }}
            >
              Select CIM10 Device
            </FormLabel>

            <TableContainer
              component={Paper}
              sx={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <ThemeProvider theme={tableCellTheme}>
                    <TableRow>
                      <TableCell>Select</TableCell>
                      <TableCell>Device Name</TableCell>
                      <TableCell>IP Address</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </ThemeProvider>
                </TableHead>
                <TableBody>
                  <ThemeProvider theme={tableBodyCellTheme}>
                    {devices.map((device) => (
                      <TableRow key={device.device_id}>
                        <TableCell>
                          <RadioGroup
                            aria-label="cim10"
                            name="cim10"
                            value={selectedDevice?.device_id || ""}
                            onChange={handleRadioChange}
                          >
                            <FormControlLabel
                              value={device.device_id}
                              control={<Radio />}
                              label=""
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell>
                          {device.device_name || "Unnamed Device"}
                        </TableCell>
                        <TableCell>{device.ip_address}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <CircleIcon
                              sx={{ color: getStatusColor(device.status) }}
                            />
                            <Typography
                              sx={{ color: getStatusColor(device.status) }}
                            >
                              {device.status === "online"
                                ? "Connected"
                                : device.status === "offline"
                                  ? "Disconnected"
                                  : "Unknown"}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ThemeProvider>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsModalOpen(false)}
                
              >
                Select
              </Button>
            </Box> */}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
              // sx={{
              //   display: "flex",
              //   justifyContent: "inherit",
              //   mt: 2,
              //   position: "fixed",
              //   // bottom: 16,
              //   // right: 16,
              //   // width: "calc(100% - 32px)", // Adjust for padding
              // }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsModalOpen(false)}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  // Add your select logic here
                  setIsModalOpen(false);
                }}
                sx={{
                  fontWeight: "bold",
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>
      <Box
        sx={{ p: 2, top: 3, gap: 2, display: "flex", flexDirection: "column" }}
      >
        <Typography variant="subtitle2" sx={{ color: "#777" }}>
          {/* IP: {selectedDevice.ip_address} */}
        </Typography>
        {selectedDevice && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              p: 1,
              border: "1px solid lightgray",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
              width: "fit-content",
              // left: 1
            }}
          >
            <Tooltip
              title={getStatusMessage(selectedDevice.status)}
              // title="message"
              arrow
            >
              <CircleIcon
                sx={{
                  // color: getStatusColor(devices.find((d) => d.device_id === selectedDevice)?.status),
                  color: getStatusColor(selectedDevice.status),
                  fontSize: 20,
                }}
              />
            </Tooltip>
            <Typography variant="subtitle1" sx={{ color: "#333" }}>
              {/* {devices.find((d) => d.device_id === selectedDevice)?.device_name || 'Unnamed Device'} */}
              {selectedDevice.device_name || "Unnamed Device"}
            </Typography>
            {/* <Typography variant="subtitle2" sx={{ color: '#777' }}>
            IP: {selectedDevice.ip_address}
          </Typography> */}
            <IconButton onClick={() => setIsModalOpen(true)}>
              <SettingsIcon sx={{ color: "black" }} />
            </IconButton>
          </Stack>
        )}
        {/* <IconButton onClick={() => setIsModalOpen(true)}>
          <SettingsIcon sx={{ color: "black" }} />
        </IconButton> */}

        <Button
          variant="contained"
          color="secondary"
          onClick={handlePushConfig}
          sx={{
            right: 6,
            backgroundColor: theme.palette.secondary.main,
            fontWeight: "bold",
            // boxShadow: "none",
          }}
          startIcon={<PublishIcon />}
        >
          Push Config
        </Button>
      </Box>

      <Snackbar
        open={pushConfigSnackbar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() =>
          setPushConfigSnackbar({ ...pushConfigSnackbar, open: false })
        }
      >
        <Alert
          severity="info"
          sx={{ width: "100%", height: "10%" }}
          onClose={() =>
            setPushConfigSnackbar({ ...pushConfigSnackbar, open: false })
          }
        >
          {pushConfigSnackbar.message}
        </Alert>
      </Snackbar>

      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}

      {successMessage && (
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={4000}
          onClose={() => setSuccessMessage("")}
          message={successMessage}
        />
      )}
    </Box>
  );
};

export default DeviceList;
