import { colors, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// import CloseIcon from "@mui/icons-material/Close";
// // import { useNavigate } from "react-router-dom";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import ShortLogo from "../../assets/images/cimcopilot_short.png";
// import ConfigurationsPage from "../configurationPage/configurationPage";
// import NewChatButton from "./newChat";
import ChatbotResponse from "./chatbotResponse";
import UserQuestion from "./useQuestion";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FilterListIcon from "@mui/icons-material/FilterList";
import DataSheets from "../DatasheetPage/DatasheetPage";
import ArticleIcon from "@mui/icons-material/Article";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import FleetManager from "../cimPage/FleetManager";
import "./SideBar.css";
import copilot from "../../assets/images/copilot.png";

import React, { useEffect, useState, useCallback } from "react";
// import { useConversations } from "../../context/conversationContext";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Modal,
  IconButton,
  Snackbar,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Chip,
  MenuList,
} from "@mui/material";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ConversationFilterDropdown from "./ConversationFilterDropDown";
import ConfigurationsPage from "../configurationPage/configurationPage";
import MasterSearch from "../MasterSearch/MasterSearch";
import DevicesIcon from "@mui/icons-material/Devices";
import NewChatButton from "./newChat";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
    // background: {
    //   default: '#F4F6F9', // Light background color
    // },
    // text: {
    //   primary: '#333333', // Dark text color
    // }
  },
});

const categorizeConversations = (conversations) => {
  const now = new Date();

  const categorized = {
    // All: [], // New category for displaying all chats
    Today: [],
    Yesterday: [],
    "Last 7 Days": [],
    "Last 30 Days": [],
    // Dynamic months like "August", "July", "June", etc.
  };

  conversations.forEach((conversation) => {
    const createdAt = new Date(conversation.created_at);
    const diffDays = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));

    // // Push conversation to the "All" category
    // categorized["All"].push(conversation);

    if (diffDays === 0) {
      categorized["Today"].push(conversation);
    } else if (diffDays === 1) {
      categorized["Yesterday"].push(conversation);
    } else if (diffDays <= 7) {
      categorized["Last 7 Days"].push(conversation);
    } else if (diffDays <= 30) {
      categorized["Last 30 Days"].push(conversation);
    } else {
      const monthYear = createdAt.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!categorized[monthYear]) {
        categorized[monthYear] = [];
      }
      categorized[monthYear].push(conversation);
    }
  });

  // Function to get categorized conversations
  return categorized;
};

const Sidebar = ({ handleNewChat }) => {
  // const theme = useTheme();
  const [conversations, setConversations] = useState([]);
  // const { conversations, fetchConversations } = useConversations();

  const [messages, setMessages] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [filteredConversations, setFilteredConversations] = useState({});
  const [open, setOpen] = useState(false);
  // const [user, setUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [starredConversations, setStarredConversations] = useState([]);
  const [unstarredConversations, setUnstarredConversations] = useState([
    // Add initial conversation data here
  ]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [showMore, setShowMore] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("Today");
  const [masterSearchOpen, setMasterSearchOpen] = useState(false);
  const [openDatasheetsModal, setOpenDatasheetsModal] = useState(false); // New state for datasheets modal
  const [fileTitles, setFileTitles] = useState("");
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  const [
    isConversationFilterDropDownVisible,
    setIsConversationFilterDropDownVisible,
  ] = useState(false);

  const toggleConversationFilterDropDowVisibility = () => {
    setIsConversationFilterDropDownVisible(
      !isConversationFilterDropDownVisible,
    );
  };

  // const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  // console.log("auth: ", authTokens)
  // const accessToken = authTokens?.access;
  // console.log("Access Token: ", accessToken);

  // const handleRedirectConfigFiles = () => {
  //   navigate("/configuration");
  // };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [isFetching, setIsFetching] = useState(false);

  // const fetchConversations = useCallback(async () => {
  //   if (isFetching) return;

  //   setIsFetching(true);
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/chat-history/${userId}/`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const sortedConversations = response.data.sort(
  //         (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //       );
  //       setConversations(sortedConversations);
  //     } else {
  //       throw new Error("Failed to fetch conversations");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching conversations:", error.message);
  //   } finally {
  //     setIsFetching(false);
  //   }
  // }, [userId, isFetching]);

  // const fetchConversations = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/chat-history/${userId}/`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           // 'X-Authorization': `Bearer ${accessToken}`
  //         },
  //       },
  //     );

  //     if (response.status === 200) {
  //       console.log("Fetched conversations successfully");

  //       // Assuming each conversation has a 'created_at' attribute
  //       const sortedConversations = response.data.sort(
  //         (a, b) => new Date(b.created_at) - new Date(a.created_at),
  //       );

  //       setConversations(sortedConversations);
  //     } else {
  //       throw new Error("Failed to fetch conversations");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching conversations:", error.message);
  //   }
  // };

  const fetchConversations = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/chat-history/${userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200) {
        const sortedConversations = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
        setConversations(sortedConversations);
      } else {
        throw new Error("Failed to fetch conversations");
      }
    } catch (error) {
      console.error("Error fetching conversations:", error.message);
    }
  }, [userId]);

  // useEffect(() => {
  //   fetchConversations();

  //   const intervalId = setInterval(() => {
  //     fetchConversations();
  //   }, 10000); // Adjust interval time as needed

  //   return () => clearInterval(intervalId); // Clean up on unmount
  // }, [fetchConversations]);
  // fetchConversations();

  useEffect(() => {
    fetchConversations();

    // Optionally, set up an interval or use WebSocket for real-time updates
    // const intervalId = setInterval(fetchConversations, 10000);
    const intervalId = setInterval(fetchConversations, 50000);
    return () => clearInterval(intervalId);
  }, [fetchConversations]);
  // fetchConversations();

  // useEffect(() => {
  //   fetchConversations();
  // }, [userId]);

  // const fetchConversationMessages = async (conversationId) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_URL}/api/get_chat_history_by_conv_id/${conversationId}/`,
  //     );

  //     if (response.status === 200) {

  //       // const messagesData = response.data.messages;
  //       // // const { messages, datasheet } = response.data;
  //       // setMessages(messagesData);
  //       // setOpen(true);

  //       const { messages, datasheet } = response.data;
  //       console.log(JSON.stringify(response.data))
  //     setMessages(messages);
  //     // setFile(datasheet.pdf_title); // Store the PDF title
  //     console.log(datasheet)
  //     setOpen(true);
  //     setFileTitle(datasheet.pdf_title);
  //     } else {
  //       throw new Error("Failed to fetch message history");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching message history:", error.message);
  //   }
  // };

  const fetchConversationMessages = async (conversationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/get_chat_history_by_conv_id/${conversationId}/`,
      );

      if (response.status === 200) {
        const { messages, datasheets } = response.data;
        console.log(JSON.stringify(response.data));

        // Set the messages
        setMessages(messages);

        // Set the PDF titles
        const pdfTitles = datasheets.map((datasheet) => datasheet.pdf_title);
        setFileTitles(pdfTitles); // Assume you have a state named `fileTitles`

        // Log the data for debugging
        console.log(datasheets);

        // Open or handle any UI changes
        setOpen(true);
      } else {
        throw new Error("Failed to fetch message history");
      }
    } catch (error) {
      console.error("Error fetching message history:", error.message);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/api/delete_conversation/${selectedConversationId}/`,
      );

      if (response.status === 204) {
        setConversations(
          conversations.filter((conv) => conv.id !== selectedConversationId),
        );
        setMessages([]);
        setSelectedConversation(null);
        setSnackbarOpen(true);
      } else {
        throw new Error("Failed to delete conversation");
      }
    } catch (error) {
      console.error("Error deleting conversation:", error.message);
    } finally {
      setDialogOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setDialogOpen(false);
  };

  const handleConfirmRename = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/update_conversation/${selectedConversationId}/`,
        { title: newTitle },
      );

      if (response.status === 200) {
        setConversations(
          conversations.map((conv) =>
            conv.id === selectedConversationId
              ? { ...conv, title: newTitle }
              : conv,
          ),
        );
        setSnackbarOpen(true);
      } else {
        throw new Error("Failed to rename conversation");
      }
    } catch (error) {
      console.error("Error renaming conversation:", error.message);
    } finally {
      setRenameDialogOpen(false);
    }
  };

  const handleCancelRename = () => {
    setRenameDialogOpen(false);
  };

  const handleConversationClick = (conversationId) => {
    setSelectedConversation(conversationId);
    fetchConversationMessages(conversationId);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedConversation(null);
    setMessages([]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDeviceModal = () => {
    setOpenDeviceModal(true); // Open Device Modal
  };

  const handleCloseDeviceModal = () => {
    setOpenDeviceModal(false); // Close Device Modal
  };

  const handleClickMenu = (event, conversationId) => {
    setAnchorEl(event.currentTarget);
    setSelectedConversationId(conversationId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirmationOpen = () => {
    handleCloseMenu();
    setDialogOpen(true);
  };

  const handleRenameConfirmationOpen = () => {
    handleCloseMenu();
    const conversation = conversations.find(
      (conv) => conv.id === selectedConversationId,
    );
    setNewTitle(conversation.title);
    setRenameDialogOpen(true);
  };

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 5); // Increase by 5 or desired number
    if (visibleCount >= conversations.length) {
      setShowMore(false); // Hide "See More" if all conversations are shown
    }
  };

  const handleSeeLess = () => {
    setVisibleCount(5); // Reset to initial count
    setShowMore(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const filteredConversations = conversations.filter((conversation) =>
  //   conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    const categorized = categorizeConversations(conversations);
    setFilteredConversations(categorized);
  }, [conversations]);

  const filteredChats =
    filteredConversations[selectedFilter]?.filter((conversation) =>
      conversation.title.toLowerCase().includes(searchTerm.toLowerCase()),
    ) || [];

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const toggleStarConversation = (conversationId) => {
    if (starredConversations.includes(conversationId)) {
      setStarredConversations(
        starredConversations.filter((id) => id !== conversationId),
      );
    } else {
      setStarredConversations([...starredConversations, conversationId]);
    }
  };

  // const toggleStarConversation = (conversationId) => {
  //   if (starredConversations.includes(conversationId)) {
  //     // Move from starred to unstarred
  //     setStarredConversations(starredConversations.filter(id => id !== conversationId));
  //     setUnstarredConversations([
  //       ...unstarredConversations,
  //       // Add the conversation back to unstarred
  //       starredConversations.find(id => id === conversationId),
  //     ]);
  //   } else {
  //     // Move from unstarred to starred
  //     setUnstarredConversations(unstarredConversations.filter(id => id !== conversationId));
  //     setStarredConversations([
  //       ...starredConversations,
  //       // Add the conversation to starred
  //       unstarredConversations.find(id => id === conversationId),
  //     ]);
  //   }
  // };

  const handleMasterSearchOpen = () => {
    setMasterSearchOpen(true);
  };

  const handleMasterSearchClose = () => {
    setMasterSearchOpen(false);
  };

  const handleOpenDatasheetsModal = () => setOpenDatasheetsModal(true); // Open datasheets modal
  const handleCloseDatasheetsModal = () => setOpenDatasheetsModal(false); // Close datasheets modal

  const MessageDisplay = ({ messages }) => {
    return (
      // <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
      // <Grid container justifyContent="center">
      // <Grid item
      // xs={12}
      // sm={8}
      // container justifyContent="center">
      // <Paper
      //   sx={{
      //     padding: theme.spacing(2),
      //     margin: "20px auto",
      //     height: "calc(100vh - 100px)",
      //     overflowY: "auto",
      //   }}

      // >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {/* <Box
  sx={{
    padding: 2,
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ddd",
    textAlign: "center",
  }}
 >
  {fileTitle && (
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      {fileTitle}
    </Typography>
  )}
</Box>  */}

        <Box
          sx={{
            padding: 2,
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #ddd",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            {fileTitles.length > 0 ? (
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", marginRight: 1 }}
              >
                Attached PDFs:
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", marginRight: 1 }}
              >
                {/* Leave this blank or add placeholder text */}
              </Typography>
            )}

            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {fileTitles.map((title, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ fontStyle: "italic" }}
                >
                  {title}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  // backgroundColor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                  borderRadius: 2,
                  padding: theme.spacing(1),
                  margin: theme.spacing(1, 0),
                  alignSelf: "flex-end",
                  maxWidth: "80%",
                  wordBreak: "break-word",
                }}
              >
                {/* <PersonIcon sx={{ marginRight: 2 }} /> */}
                <UserQuestion
                  text={message.question}
                  // fileTitles={fileTitles}
                />
                {/*             
        {fileTitles.map((title, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              marginBottom: "8px",
              padding: "4px",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f5f5f5",
              maxWidth: "80%",
            }}
          >
            <Chip
              icon={<ArticleIcon />}
              label={title.length > 20 ? `${title.slice(0, 30)}...` : title}
              variant="outlined"
              sx={{
                marginRight: "8px",
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        ))} */}

                <ChatbotResponse
                  text={message.answer}
                  enableTextEffect={false}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      // </Paper>
      // </Grid>
      /* </Grid> */
      /* // </Box>  */
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex" }}>
        <Box
          sx={{
            flex: "1",
            padding: "20px",
            //     display: "flex",
            // flexDirection: "column",
            // height: "100vh", // Set full height for the sidebar
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Avatar
              src={ShortLogo}
              alt="CIMCopilot Logo"
              sx={{ width: 24, height: 24, marginRight: 2 }}
            />
            <Typography variant="h6" style={{ marginTop: 6 }}>
              CIMCopilot
            </Typography>
          </Box> */}
          <div className="logo">
            <img
              src={copilot}
              alt="logo"
              style={{
                width: "100%", // Make sure the logo fits within its container
                maxWidth: "220px", // Set a maximum width to avoid it being too large
                margin: "0 auto", // Center horizontally within the parent container
                display: "block", // Ensure it behaves like a block element for centering
                marginBottom: "20px",
                marginTop: "-30px",
              }}
            />
          </div>

          {/* <NewChatButton
  sx={{
    margin: "10px 20px 0px",              // Match the margin with other buttons
    backgroundColor: theme.palette.secondary.main, // Same background color
    fontWeight: "bold",                     // Same font weight
    width: "116%",                          // Same width as other buttons
    left: -40,                              // Same left position
    // boxShadow: "none",                      // No box shadow, same as other buttons
    borderRadius: "5px",                    // Same border radius
    boxShadow: 3,                           // Same box shadow effect
                                  // Adjust as needed for alignment
    // bottom: 10,                             // Adjust as needed for spacing
    
  }}
  onNewChat={handleNewChat}
/> */}

          {/* <Button
            onClick={handleOpenModal}
            color="secondary"
            variant="contained"
            size="medium"
            sx={{
              margin: "0px 20px 10px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              width: "116%",
              left: -40,
              justifyContent: "flex-start", // Align content to the left
              textAlign: "left",
              
        
            
            }}
            startIcon={<BuildCircleIcon sx={{ fontSize: 30 }} />}
          >
            Configrations
          </Button>
          <Button
            onClick={handleOpenDatasheetsModal} // Open datasheets modal
            variant="contained"
            color="secondary"
            sx={{
              margin: "10px 20px 20px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              width: "116%",
              left: -40,
              justifyContent: "flex-start", // Align content to the left
              textAlign: "left",
              // top: 1
            }}
            startIcon={<ArticleIcon sx={{ fontSize: 30 }} />} // Adjust fontSize as needed
            >
            Datasheets
          </Button>
          <Button
            onClick={handleOpenDeviceModal}  // Open Device Modal on button click
            color="secondary"
            variant="contained"
           
            // sx={{ margin: "20px", backgroundColor: theme.palette.secondary.main, fontWeight:"bold" }}
            sx={{
              // margin: "20px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              boxShadow: "none",
              borderRadius: "5px",
              width: "116%",
              boxShadow: 3,
              left: -20,
              top: 1,
              bottom: 10,
              marginBottom: 3,
              justifyContent: "flex-start", // Align content to the left
              textAlign: "left",
            }}
            startIcon={<DevicesIcon/>}
          >
            Fleet Manager
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              // margin: "20px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              boxShadow: "none",
              borderRadius: "5px",
              width: "116%",
              boxShadow: 3,
              left: -20,
              top: 1,
              bottom: 10,
              justifyContent: "flex-start", // Align content to the left
              textAlign: "left",
            }}
            startIcon={<SearchIcon />}
            onClick={handleMasterSearchOpen}
          >
            Search
          </Button> */}

          <MenuList sx={{ width: "250px", padding: 0 }}>
            <MenuItem sx={{ padding: 0 }}>
              <NewChatButton
                sx={{
                  // margin: "10px 20px 0px",             // Same margin as other buttons
                  backgroundColor: theme.palette.secondary.main, // Same background color
                  fontWeight: "bold", // Same font weight
                  // width: "116%",                       // Same width as other buttons
                  // left: -40,                           // Same left position
                  borderRadius: "5px", // Same border radius
                  boxShadow: 3, // Same box shadow effect
                  // justifyContent: "flex-start",        // Align content to the left
                  // textAlign: "left",                   // Left-align text
                  // paddingLeft: "10px",                 // Padding to align with others
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark, // Hover effect
                  },
                }}
                onNewChat={handleNewChat}
              />
            </MenuItem>
            {/* Configurations Button */}
            <MenuItem sx={{ padding: 0 }}>
              <Button
                onClick={handleOpenModal}
                color="secondary"
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  fontWeight: "bold",
                  width: "100%",
                  justifyContent: "flex-start", // Align content to the left
                  textAlign: "left",
                  paddingLeft: "10px", // Ensure the text has some padding from the left
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark, // Hover effect
                  },
                }}
                startIcon={<BuildCircleIcon sx={{ fontSize: 30 }} />}
              >
                Configurations
              </Button>
            </MenuItem>

            {/* Datasheets Button */}
            <MenuItem sx={{ padding: 0 }}>
              <Button
                onClick={handleOpenDatasheetsModal}
                color="secondary"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  fontWeight: "bold",
                  width: "100%",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  paddingLeft: "10px",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
                startIcon={<ArticleIcon sx={{ fontSize: 30 }} />}
              >
                Datasheets
              </Button>
            </MenuItem>

            {/* Fleet Manager Button */}
            <MenuItem sx={{ padding: 0 }}>
              <Button
                onClick={handleOpenDeviceModal}
                color="secondary"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  fontWeight: "bold",
                  width: "100%",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  paddingLeft: "10px",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
                startIcon={<DevicesIcon sx={{ fontSize: 30 }} />}
              >
                Fleet Manager
              </Button>
            </MenuItem>

            {/* Search Button */}
            <MenuItem sx={{ padding: 0 }}>
              <Button
                onClick={handleMasterSearchOpen}
                color="secondary"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  fontWeight: "bold",
                  width: "100%",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  paddingLeft: "10px",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                  },
                }}
                startIcon={<SearchIcon sx={{ fontSize: 30 }} />}
              >
                Search
              </Button>
            </MenuItem>
          </MenuList>

          {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            zIndex: 10,
            padding: '20px',
            width: '100%',
          }}
        >
          <Button
            onClick={handleOpenModal}
            color="secondary"
            variant="contained"
            size="medium"
            sx={{
              margin: "20px 20px 10px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              width: "116%",
              left: -40,
            }}
          >
            Configurations
          </Button>
          <Button
            onClick={handleOpenDatasheetsModal} // Open datasheets modal
            variant="contained"
            color="secondary"
            sx={{
              margin: "10px 20px 20px",
              backgroundColor: theme.palette.secondary.main,
              fontWeight: "bold",
              width: "116%",
              left: -40,
            }}
          >
            Datasheets
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              fontWeight: "bold",
              boxShadow: "none",
              borderRadius: "5px",
              width: "116%",
              boxShadow: 3,
              left: -20,
              top: 1,
              bottom: 10,
            }}
            startIcon={<SearchIcon />}
            onClick={handleMasterSearchOpen}
          >
            Search
          </Button>
        </Box>
        </div> */}
          <Dialog
            open={masterSearchOpen}
            onClose={handleMasterSearchClose}
            fullWidth
            maxWidth="md"
            sx={{
              "& .MuiDialogTitle-root": {
                padding: theme.spacing(2),
                position: "relative",
                backgroundColor: theme.palette.background.paper,
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
              "& .MuiDialogContent-root": {
                padding: theme.spacing(2),
              },
              backdropFilter: "blur(6px)",
            }}
          >
            <DialogTitle
              sx={{
                color: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                // fontSize: "2rem",
                "@media (max-width:600px)": {
                  fontSize: "1.5rem",
                },
              }}
            >
              Search
            </DialogTitle>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMasterSearchClose}
              aria-label="close"
              sx={{
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.text.secondary,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  padding: "8px", // Padding for a round effect
                  borderRadius: "50%", // Round shape for the icon
                  transition: "background-color 0.3s, color 0.3s", // Smooth transition
                  "&:hover": {
                    backgroundColor: "#D3D3D3", // ThingsBoard primary color on hover
                    color: theme.palette.primary.main, // Icon color change on hover
                  },
                }}
              />
            </IconButton>
            <DialogContent>
              <MasterSearch />
            </DialogContent>
          </Dialog>
          {/* <Divider /> */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="configurations-modal-title"
            aria-describedby="configurations-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(4px)",
            }}
          >
            <Box
              sx={{
                width: "90%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 3,
                borderRadius: 2,
                overflow: "hidden",
                maxHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <ConfigurationsPage onClose={handleCloseModal} />
            </Box>
          </Modal>
          <Modal
            open={openDatasheetsModal}
            onClose={handleCloseDatasheetsModal}
            aria-labelledby="datasheets-modal-title"
            aria-describedby="datasheets-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(4px)",
            }}
          >
            <Box
              sx={{
                width: "90%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 0, // Remove padding from the container so it's handled inside
                borderRadius: 2,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              {/* Sticky Header */}
              <Box
                sx={{
                  position: "sticky", // Makes the header stick at the top
                  top: 0, // Stick it to the top
                  zIndex: 10, // Ensure it stays above other content
                  backgroundColor: "background.paper", // Match modal background
                  pt: 2, // Padding top
                  pr: 2, // Padding right
                  pb: 0, // Padding bottom
                  pl: 2, // Padding left,  // Add some padding inside the header
                  borderBottom: "1px solid #ddd", // Optional: Adds a subtle bottom border for separation
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    id="datasheets-modal-title"
                    sx={{
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: "2rem",
                      "@media (max-width:600px)": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    <ArticleIcon
                      sx={{
                        marginRight: "8px",
                        fontSize: "2rem",
                      }}
                    />
                    Datasheets
                  </Typography>

                  <CloseIcon
                    onClick={handleCloseDatasheetsModal}
                    sx={{
                      cursor: "pointer",
                      padding: "8px", // Padding for a round effect
                      borderRadius: "50%", // Round shape for the icon
                      transition: "background-color 0.3s, color 0.3s", // Smooth transition
                      "&:hover": {
                        backgroundColor: "#D3D3D3", // ThingsBoard primary color on hover
                        color: theme.palette.primary.main, // Icon color change on hover
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* Datasheets Content */}
              <Box sx={{ p: 3 }}>
                <DataSheets /> {/* Render your DataSheets component here */}
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openDeviceModal}
            onClose={handleCloseDeviceModal}
            aria-labelledby="device-modal-title"
            aria-describedby="device-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(4px)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "1200px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 3,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  pb: 1,
                  position: "relative",
                }}
              >
                <Typography
                  id="device-modal-title"
                  variant="h6"
                  // sx={{
                  //   fontWeight: 'bold',
                  //   color: 'text.primary',
                  // }}
                  sx={{
                    color: theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: "2rem",
                    "@media (max-width:600px)": {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                  <DevicesIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "2rem",
                    }}
                  />
                  Fleet Manager
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseDeviceModal}
                  aria-label="close"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    mt: 0.5,
                    mr: 0.5,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              {/* <DeviceForm />  Render the DeviceForm inside the modal */}
              <FleetManager />
            </Box>
          </Modal>

          {/* <Box sx={{ flex: 1, overflowY: "auto" }}>
            <List>
              {conversations.map((conversation) => (
                <ListItem
                  key={conversation.id}
                  button
                  onClick={() => handleConversationClick(conversation.id)}
                  sx={{
                    borderRadius: "10px",
                    boxShadow: "none",
                    textDecoration:"",
                    // margin: "8px 0",
                    transition: "background-color 0.3s ease, transform 0.2s ease",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.light,
                      transform: "scale(1.02)",
                    },
                    "&:active": {
                      transform: "scale(0.98)",
                    },
                  }}
                >
                  <ListItemText
                    primary={conversation.title}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      // fontFamily: "cursive",
                      color: theme.palette.text.primary,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box> */}

          {/* <MasterSearch /> */}

          {/* 
          <div style={{ paddingTop: 14 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Filter Conversation">
                <IconButton onClick={toggleConversationFilterDropDowVisibility}>
                  <FilterListIcon sx={{ color: "black" }}/>
                </IconButton>
              </Tooltip>
            </div>

            {isConversationFilterDropDownVisible && (
              <div style={{ paddingTop: 14 }}>
                <ConversationFilterDropdown
                  filteredConversations={filteredConversations}
                  selectedFilter={selectedFilter}
                  handleFilterChange={handleFilterChange}
                />
              </div>
            )}
          </div> */}

          <div style={{ paddingTop: "1rem" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title="Filter Conversation" arrow>
                <IconButton
                  onClick={toggleConversationFilterDropDowVisibility}
                  sx={{
                    backgroundColor: "#f0f0f0",
                    transition:
                      "background-color 0.3s ease, transform 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <FilterListIcon sx={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            </div>

            {isConversationFilterDropDownVisible && (
              <div
                style={{
                  paddingTop: "1rem",
                  transition: "opacity 0.5s ease",
                  opacity: isConversationFilterDropDownVisible ? 1 : 0,
                }}
              >
                <ConversationFilterDropdown
                  filteredConversations={filteredConversations}
                  selectedFilter={selectedFilter}
                  handleFilterChange={handleFilterChange}
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "1rem",
                    width: "100%",
                    maxWidth: "400px",
                    margin: "auto",
                    transition: "all 0.3s ease",
                  }}
                />
              </div>
            )}
          </div>
          {/* <ConversationFilterDropdown
              filteredConversations={filteredConversations}
              selectedFilter={selectedFilter}
              handleFilterChange={handleFilterChange}
            /> */}

          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {starredConversations.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold", // Makes the text bold
                    color: theme.palette.primary.main, // Optional: Set custom text color
                    textTransform: "uppercase", // Optional: Transforms text to uppercase
                    padding: "8px 0", // Optional: Add some padding for spacing
                    // letterSpacing: "0.1em",     // Optional: Adds spacing between letters
                    fontSize: "1rem", // Optional: Adjust font size
                  }}
                >
                  starred
                </Typography>
                <List
                  sx={{
                    padding: 0, // Remove any padding
                    marginTop: "-5px", // Reduce space above the list
                    marginBottom: "0px", // Reduce space below the list
                  }}
                >
                  {conversations
                    .filter((conv) => starredConversations.includes(conv.id))
                    .map((conversation) => (
                      <ListItem
                        key={conversation.id}
                        button
                        onClick={() => handleConversationClick(conversation.id)}
                        sx={{
                          // top: -15,

                          borderRadius: "10px",
                          boxShadow: "none",
                          transition:
                            "background-color 0.3s ease, transform 0.2s ease",
                          "&:hover": {
                            backgroundColor: theme.palette.secondary.light,
                            transform: "scale(1.02)",
                          },
                          "&:active": {
                            transform: "scale(0.98)",
                          },
                        }}
                      >
                        <ListItemText
                          primary={conversation.title}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            // whiteSpace: "nowrap",
                            color: theme.palette.text.primary,
                          }}
                        />
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleStarConversation(conversation.id);
                          }}
                        >
                          <StarIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                </List>
                <Divider
                  sx={{
                    // backgroundColor: "black",
                    // top: -20,
                    marginTop: "7px",
                    marginBottom: "0px",
                    width: "90%",
                  }}
                />
              </>
            )}
            {/* {conversations.length === 0 ? ( */}
            {filteredChats?.length === 0 ? (
              <Typography
                variant="body1"
                sx={{
                  marginTop: 2,
                  padding: 2,
                  backgroundColor: "#EDEFF2", // ThingsBoard light gray background
                  color: "#333333", // Darker text color for better readability
                  fontStyle: "italic",
                  textAlign: "center",
                  borderRadius: "4px",
                  border: "1px solid #D1D4DA", // Border color matching ThingsBoard's palette
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                  fontSize: "0.875rem", // Slightly smaller font size for a cleaner look
                  display: "",
                }}
              >
                No chats to display!
              </Typography>
            ) : (
              <List>
                {filteredChats.slice(0, visibleCount).map((conversation) => (
                  // {conversations.slice(0, visibleCount).map((conversation) => (
                  <ListItem
                    key={conversation.id}
                    button
                    onClick={() => handleConversationClick(conversation.id)}
                    sx={{
                      borderRadius: "10px",
                      boxShadow: "none",
                      textDecoration: "none",
                      transition:
                        "background-color 0.3s ease, transform 0.2s ease",
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                        // transform: "scale(1.02)",
                      },
                      "&:active": {
                        // transform: "scale(0.98)",
                      },
                      position: "relative",
                      overflow: "hidden",
                      width: "100%",
                      left: 0,
                      right: 10,
                    }}
                  >
                    <Tooltip title="Add to Favourites" arrow>
                      <IconButton
                        //  sx={{ marginRight: 3 }}
                        sx={{
                          marginRight: 2,
                          flexShrink: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the list item click
                          toggleStarConversation(conversation.id);
                        }}
                      >
                        {starredConversations.includes(conversation.id) ? (
                          <StarIcon />
                        ) : (
                          <StarBorderIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <ListItemText
                      primary={conversation.title}
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        // whiteSpace: 'nowrap',
                        textOverflow: "ellipsis",
                        color: theme.palette.text.primary,
                        "&:hover::before": {
                          content: '""',
                          // position: "absolute",
                          top: 0,
                          right: 0,
                          height: "100%",
                          width: "100%",
                          // background: `linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%)`,
                          zIndex: 1,
                        },
                        "&:hover": {
                          whiteSpace: "normal",
                          textOverflow: "clip",
                          cursor: "pointer",
                        },
                      }}
                    />

                    <Tooltip title="Options" arrow>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent triggering the list item click
                          handleClickMenu(event, conversation.id);
                        }}
                        sx={{
                          position: "absolute",
                          right: 8,
                          marginLeft: 16, // Increased marginLeft to create more space
                          zIndex: 2, // Ensure the IconButton is above the ListItemText
                          opacity: 0,
                          transition: "visibility 0s, opacity 0.2s linear",
                          "&:hover": {
                            visibility: "visible",
                            opacity: 1,
                          },
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            )}
            {/* {showMore && conversations.length > visibleCount && ( */}
            {showMore && filteredChats.length > visibleCount && (
              <Button
                onClick={handleSeeMore}
                variant="outlined"
                color="primary"
                sx={{ marginTop: 2 }}
              >
                See More
              </Button>
            )}
            {visibleCount > 10 && (
              <Button
                onClick={handleSeeLess}
                variant="filled"
                color="primary"
                sx={{ marginTop: 2 }}
              >
                See Less
              </Button>

              // {(filteredChats.length > visibleCount && visibleCount <= 10) && (
              //   <Button
              //     onClick={handleSeeMore}
              //     variant="outlined"
              //     color="primary"
              //     sx={{ marginTop: 2 }}
              //   >
              //     See More
              //   </Button>
              // )}
              // {(visibleCount > 10 && filteredChats.length > 10) && (
              //   <Button
              //     onClick={handleSeeLess}
              //     variant="outlined"
              //     color="secondary"
              //     sx={{ marginTop: 2 }}
              //   >
              //     See Less
              //   </Button>
            )}
          </Box>
        </Box>

        <Modal
          open={open}
          // onClose={handleClose}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(4px)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "90%",
              maxWidth: "800px",
              bgcolor: theme.palette.background.paper,
              boxShadow: theme.shadows[24],
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
              border: `1px solid ${theme.palette.divider}`,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <IconButton
              aria-label="close"
              // onClick={handleClose}
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedConversation && <MessageDisplay messages={messages} />}
          </Box>
        </Modal>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{
            // Apply custom styling to Menu
            "& .MuiMenu-paper": {
              backgroundColor: "var(--menu-background-color, #ffffff)", // Using CSS variables
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "8px",
              width: "200px",
            },
            "& .MuiMenuItem-root": {
              borderRadius: "4px",
              padding: "8px 16px",
              fontSize: "14px",
              color: "var(--menu-text-color, #333333)", // Using CSS variables
              "&:hover": {
                backgroundColor: "var(--menu-item-hover-background, #f5f5f5)", // Using CSS variables
                color: "var(--menu-item-hover-color, #000000)", // Using CSS variables
              },
              "&.Mui-selected": {
                backgroundColor:
                  "var(--menu-item-selected-background, #e0e0e0)", // Using CSS variables
                color: "var(--menu-item-selected-color, #000000)", // Using CSS variables
              },
            },
            "& .MuiMenuItem-root .MuiSvgIcon-root": {
              marginRight: "8px", // Space between icon and text
            },
            boxShadow: 0.5,
          }}
        >
          <MenuItem onClick={handleRenameConfirmationOpen}>
            <CreateIcon />
            <Typography variant="body2">Rename</Typography>
          </MenuItem>
          <MenuItem onClick={handleDeleteConfirmationOpen}>
            <DeleteIcon />
            <Typography variant="body2">Delete</Typography>
          </MenuItem>
        </Menu>

        <Dialog
          open={dialogOpen}
          onClose={handleCancelDelete}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "12px", // Softer corners for a modern look
              padding: "16px", // Add padding for content
              minWidth: "400px", // Ensure the dialog has a decent width
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "18px",
              fontWeight: "600", // Make the title bold
              marginBottom: "8px", // Add some space below the title
            }}
          >
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                fontSize: "15px",
                color: "text.secondary", // Use a muted color for the description
                lineHeight: "1.6", // Improve readability
                marginBottom: "16px", // Space between content and actions
              }}
            >
              Are you sure you want to delete this conversation? This action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-end", padding: "8px 16px 16px" }}
          >
            <Button
              onClick={handleCancelDelete}
              sx={{
                color: "text.primary",
                // fontWeight: "500", // Slightly bolder text for better emphasis
                fontWeight: "bold",
                textTransform: "none", // Avoid all-caps for a more modern feel
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained" // Make the delete button more prominent
              sx={{
                // fontWeight: "500", // Slightly bolder text for better emphasis
                textTransform: "none", // Avoid all-caps for a more modern feel
                fontWeight: "bold",
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog open={dialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this conversation? This action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog> */}

        <Dialog
          open={renameDialogOpen}
          onClose={handleCancelRename}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "12px", // Softer corners for a modern appearance
              padding: "16px", // Add padding around the dialog content
              minWidth: "400px", // Ensure sufficient width for content
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "18px",
              fontWeight: "600", // Make the title bold
              marginBottom: "8px", // Add space below the title
            }}
          >
            Rename Conversation
          </DialogTitle>
          <DialogContent sx={{ paddingBottom: "16px" }}>
            {" "}
            {/* Add space between content and actions */}
            <TextField
              autoFocus
              margin="dense"
              label="New Title"
              type="text"
              fullWidth
              variant="standard"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  fontSize: "18px", // Slightly smaller label for better balance
                },
                "& .MuiInputBase-input": {
                  fontSize: "16px", // Comfortable text size for input
                },
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-end", padding: "8px 16px 16px" }}
          >
            <Button
              onClick={handleCancelRename}
              sx={{
                color: "text.primary",
                fontWeight: "500", // Slightly bolder text for emphasis
                textTransform: "none", // Avoid all-caps for a modern look
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRename}
              color="primary"
              variant="contained" // Make the rename button more prominent
              sx={{
                fontWeight: "500", // Slightly bolder text for emphasis
                textTransform: "none", // Avoid all-caps for a modern look
                fontWeight: "bold",
                // color: "white"
              }}
            >
              Rename
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog open={renameDialogOpen} onClose={handleCancelRename}>
          <DialogTitle>Rename Conversation</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="New Title"
              type="text"
              fullWidth
              variant="standard"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelRename}>Cancel</Button>
            <Button onClick={handleConfirmRename} color="primary">
              Rename
            </Button>
          </DialogActions>
        </Dialog> */}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="Operation successful"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
